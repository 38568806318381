import React, { useEffect, useState,useCallback } from "react";
import classNames from "classnames";
import DeleteConfirmation from "../AddClientCompononents/DeleteConfirmation";
import AddCategory from "./AddCategory";
import ErrorMsg from "../AddProductComponents/ErrorMsg";
import AddSubCategory from "./AddSubCategory";
import Navbar from "../AdminNav";
import { TrashIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { DELETE_CATEGORY_API, DELETE_SUBCATEGORY_API, FETCH_CATEGORIES_API, FETCH_SUBCATEGORIES_API } from "../../Api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const AddCategories = () => {
  const [categoryName, setcategoryName] = useState(null);
  const [subCategoryName, setsubCategoryName] = useState(null);


  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [errMsgPopUp, setErrMsgPopUp] = useState(false); // Error Pop up message
  const [subCatErrMsgPopUp, setSubCatErrMsgPopUp] = useState(false); // Error Pop up message
  const [selectedCatBtnIndex, setselectedCatBtnIndex] = useState(null);
  // eslint-disable-next-line
  const [selectedSubCatBtnIndex, setselectedSubCatBtnIndex] = useState(null);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [subCatDeleteConfirmation, setSubCatDeleteConfirmation] = useState(false);

  const [addCategoryBtn, setAddCategoryBtn] = useState(false); // Add category button
  const [addSubCategoryBtn, setAddSubCategoryBtn] = useState(false); // Add sub-category button

  //  check if user is authenticated
  const navigate = useNavigate();
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const isTokenExpired = (token) => {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      const expiryTime = new Date(decodedToken.exp * 1000);
      console.log("Token expiry time:", expiryTime.toLocaleString());
      return decodedToken.exp < currentTime;
    };
    if (!authToken || isTokenExpired(authToken)) {
      console.log("AuthContext - user:", false);
      navigate("/signin");
    } else {
      console.log("AuthContext - user:", true);
    }
  }, [navigate]);

  //   styling for the selected buttons
  const getCategoryClasses = (index) =>
    classNames("cursor-pointer", {
      "font-bold": selectedCatBtnIndex === index,
      "font-normal":
        selectedCatBtnIndex !== null && selectedCatBtnIndex !== index,
    });

  const getSubCategoryClasses = (index) =>
    classNames("cursor-pointer", {
      "font-bold": selectedSubCatBtnIndex === index,
      "font-normal":
        selectedSubCatBtnIndex !== null && selectedSubCatBtnIndex !== index,
    });

  const handleAddCategoryBtn = () => {
    setAddCategoryBtn(!addCategoryBtn);
  }

  const handleAddSubCategoryBtn = () => {
    setAddSubCategoryBtn(!addSubCategoryBtn);
  }
  const handleCategoryClickBtn = (index, category) => {
    if (selectedCatBtnIndex === index) {
      setselectedCatBtnIndex(null);
      setcategoryName(null);
      setSubCategories([]);
    } else {
      setselectedCatBtnIndex(index);
      setcategoryName(category);
    }
  };

  const handleCategoryDeleteBtn = (index, category) => {
    setcategoryName(category);
    // if (categoryName == null) {
    //   setErrMsgPopUp(true);
    //   return;
    // }
    setDeleteConfirmation(!deleteConfirmation);
  };

  const handleSubCategoryDeleteBtn = (index, subCategory) => {
    setsubCategoryName(subCategory);
    // if (subCategoryName == null) {
    //   setSubCatErrMsgPopUp(true);
    //   return;
    // }
    setSubCatDeleteConfirmation(!subCatDeleteConfirmation);
  };

  const deleteCategory = async () => {
    try {
      const response = await axios.delete(DELETE_CATEGORY_API + categoryName.id
      );
      console.log(response);
      setcategoryName(null);
      setDeleteConfirmation(false);
      fetchCategoriesFromServer();
    }
    catch (error) {
      console.error("Error removing category document: ", error);
    }
  };

  const deleteSubCategory = async () => {
    console.log(
      "Inside deleteSubCategory: " + categoryName.id + " " + subCategoryName.id
    );
    try {
      const response = await axios.delete(DELETE_SUBCATEGORY_API + categoryName.id + "/" + subCategoryName.id);
      console.log(response);
      setsubCategoryName(null);
      setSubCatDeleteConfirmation(false);
      fetchSubCategoriesFromServer();
    }
    catch (error) {
      console.error("Error removing sub-category document: ", error);
    }
  };

  async function fetchCategoriesFromServer() {
    try {
      const response = await fetch(FETCH_CATEGORIES_API);
      const categoriesData = await response.json();
      setCategories(categoriesData);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }



    const fetchSubCategoriesFromServer = useCallback(async () => {
    if (!categoryName) {
      return;
    }
    try {
      const response = await fetch(FETCH_SUBCATEGORIES_API + categoryName.id);
      const subCategoriesData = await response.json();
      setSubCategories(subCategoriesData);
    } catch (error) {
      console.error('Error fetching sub-categories:', error);
    }
  }, [categoryName]);
  useEffect(() => {
    fetchCategoriesFromServer(); // Fetch categories when the component mounts
  }, []);

  useEffect(() => {
    fetchSubCategoriesFromServer(); // Fetch  sub-categories when the component mounts
  }, [fetchSubCategoriesFromServer]);

  return (
    <div>
      {/* <SignOutBtn /> */}
      <Navbar />
      {
        errMsgPopUp && (
          <ErrorMsg
            errMsg="Please select a category first."
            onClose={() => {
              setErrMsgPopUp(false);
            }}
          />
        )
      }
      {
        subCatErrMsgPopUp && (
          <ErrorMsg
            errMsg="Please select a sub-category first."
            onClose={() => {
              setSubCatErrMsgPopUp(false);
            }}
          />
        )
      }
      {
        // Delete confirmation popup
        deleteConfirmation && (
          <DeleteConfirmation
            onConfirm={() => {
              deleteCategory();
              setselectedCatBtnIndex(null);
              setSubCategories([]);
            }}
            onCancel={() => setDeleteConfirmation(false)}
          />
        )
      }
      {
        // Delete confirmation popup
        subCatDeleteConfirmation && (
          <DeleteConfirmation
            onConfirm={() => {
              deleteSubCategory();
            }}
            onCancel={() => setSubCatDeleteConfirmation(false)}
          />
        )
      }
      {
        // Add category popup

        addCategoryBtn && (
          <AddCategory
            onClose={() => {
              setAddCategoryBtn(false);
              fetchCategoriesFromServer();
            }}
          />
        )
      }

      {
        // Add sub-category popup
        addSubCategoryBtn && (
          categoryName ? (
            <AddSubCategory
              onClose={() => {
                setAddSubCategoryBtn(false);
                fetchSubCategoriesFromServer();
              }}
              categoryName={categoryName.id}
            />
          ) : (
            <ErrorMsg
              errMsg="Please select a category first."
              onClose={() => {
                setAddSubCategoryBtn(false);
              }}
            />
          )
        )
      }
      <div>
        {/* Title */}
        <div className="px-4 md:px-6 mt-8 mb-4">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
            <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900">
              Manage Categories, Sub-categories
            </h2>
          </div>
        </div>
      </div>


      {/* results */}
      <div className="flex flex-col md:flex-row w-full p-1 justify-center items-start space-y-4 md:space-y-0">
  {/* Categories Section */}
  <div className="mx-1 p-2 w-full md:w-1/2 bg-white-100 rounded-xl">
    <div className="flex justify-between items-center px-3 py-2">
      <p className="text-xl font-semibold">Categories</p>
      <button
        className="bg-green-500 hover:bg-green-600 text-white px-3 py-2 rounded-lg flex items-center"
        onClick={() => handleAddCategoryBtn()}
      >
        <span className="mr-1">Add</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      </button>
    </div>

    {categories &&
      categories.map((category, index) => (
        <div
          key={index}
          className={`flex justify-between items-center mx-1 my-2 px-4 py-4 rounded-xl bg-gray-200 hover:bg-gray-300 transition duration-300 relative group ${getCategoryClasses(index)}`}
          onClick={() => handleCategoryClickBtn(index, category)}
        >
          {/* Category Name */}
          <p className="pl-2">{category.name}</p>

          {/* Icons */}
          <div className="flex items-center space-x-2 transition duration-300">
            {/* Delete Icon */}
            <TrashIcon
              className="h-6 w-6 text-red-500 opacity-0 group-hover:opacity-100 transition-opacity duration-200 cursor-pointer mr-2"
              onClick={(e) => {
                e.stopPropagation(); // Prevents parent click event from firing
                handleCategoryDeleteBtn(index, category);
              }}
            />

            {/* Right Arrow - Always Visible for Selected Category */}
            <div
              className={`w-8 h-8 flex justify-center items-center rounded-full transition duration-300 ${selectedCatBtnIndex === index ? "bg-green-500" : "bg-gray-400 group-hover:bg-gray-500"}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-white"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M9 18l6-6-6-6" />
              </svg>
            </div>
          </div>
        </div>
      ))}
  </div>

  {/* Subcategories Section */}
  <div className="mx-1 p-2 w-full md:w-1/2 bg-white-100 rounded-xl">
    {subCategories.length > 0 && (
      <div className="flex justify-between items-center px-3 py-2">
        <p className="text-xl font-semibold">Sub Categories</p>
        <button
          className="bg-green-500 hover:bg-green-600 text-white px-3 py-2 rounded-lg flex items-center"
          onClick={() => handleAddSubCategoryBtn()}
        >
          <span className="mr-1">Add</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
        </button>
      </div>
    )}
    {subCategories.length > 0 ? (
      subCategories.map((subCategory, index) => (
        <div
          key={index}
          className={`w-full flex justify-between items-center mx-1 my-2 px-2 py-4 rounded-xl bg-gray-200 hover:bg-gray-300 ${getSubCategoryClasses(index)} group`}
        >
          {/* Sub-category Name */}
          <p className="pl-2">{subCategory.name}</p>

          {/* Trash Icon (Visible on Hover) */}
          <TrashIcon
            className="h-6 w-6 text-red-500 opacity-0 group-hover:opacity-100 transition-opacity duration-200 cursor-pointer mr-4"
            onClick={(e) => {
              e.stopPropagation(); // Prevents parent click event from firing
              handleSubCategoryDeleteBtn(index, subCategory);
            }}
          />
        </div>
      ))
    ) : (
      <div className="flex flex-col justify-center items-center h-[400px] w-full">
        <PlusCircleIcon
          className="h-8 w-8 text-green-500 cursor-pointer mb-2"
          onClick={(e) => {
            e.stopPropagation();
            handleAddSubCategoryBtn();
          }}
        />
        <p className="text-center">No sub-category to view.</p>
      </div>
    )}
  </div>
</div>


    </div>
  );
};

export default AddCategories;
