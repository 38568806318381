import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Navbar from "./AdminNav";
import HeroSection from "./MotionText";

const AdminPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");

    const isTokenExpired = (token) => {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      const expiryTime = new Date(decodedToken.exp * 1000);
      console.log("Token expiry time:", expiryTime.toLocaleString());
      return decodedToken.exp < currentTime;
    };

    if (!authToken || isTokenExpired(authToken)) {
      console.log("AuthContext - user:", false);
      navigate("/signin");
    } else {
      console.log("AuthContext - user:", true);
    }
  }, [navigate]);

  return (
    <div className="w-full h-screen flex flex-col">
      <Navbar />

      {/* Main content area, fills remaining space without forcing min-h-screen */}
      <div className="flex-1 flex items-center justify-center px-4 sm:px-8">
        <div className="w-full "> {/* Limits width, keeps text left-aligned */}
          <HeroSection />
        </div>
      </div>
      
    </div>
  );
};

export default AdminPage;
