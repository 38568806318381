import React, { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import NewNav from "../navbar/NewNav";
import Address from "../address/Address";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
    Textarea,
} from "@material-tailwind/react";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import { TailSpin } from 'react-loader-spinner';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faQuestion, faFile } from '@fortawesome/free-solid-svg-icons';

import { GET_CAREER_JOBS } from "../Api";
import { SUBMIT_JOB } from "../Api";

const JobDetail = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [step, setStep] = useState(1);

    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [resume, setResume] = useState(null);
    const [errors, setErrors] = useState({});
    const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        name: "",
        contact: "",
        email: "",
        degree: "",
        department: "",
        experience: "",
        currentLocation: "",
        permenantLocation: "",
        answers: {}
    });

    const fileTypes = ["PDF", "DOC", "DOCX"];

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const jobId = queryParams.get('jobid');

    useEffect(() => {
        window.scrollTo(0, 0);

        // Fetch jobs from the backend
        const fetchJobs = async () => {
            try {
                const response = await axios.get(GET_CAREER_JOBS, {
                    params: {
                        userSide: 'user',
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setJobs(response.data.jobs);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchJobs();
    }, []);

    const job = jobs.find(job => job.id === parseInt(jobId));

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <TailSpin
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                />
            </div>
        );
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: "" })); // Clear error for the field
    };

    const handleAnswerChange = (index, value) => {
        setFormData((prev) => ({
            ...prev,
            answers: {
                ...prev.answers, // Preserve existing answers
                [index]: value   // Update or add the specific answer
            }
        }));
        setErrors((prev) => ({
            ...prev,
            [`question-${index}`]: "" // Clear error for the field
        }));
    };


    // Handle resume file change
    const handleResumeChange = (file) => {
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes

        if (file) {
            setResume(file);
            setError("");
        } else {
            setError("Resume upload is required.");
        }

        if (file.size > maxSize) {
            setError("File size exceeds the 5MB limit. Please upload a smaller file.");
            setResume(null);
        } else {
            setError("");
            setResume(file);
        }
    };

    const openPopup = () => setIsOpen(true);
    const closePopup = () => setIsOpen(false);

    const handleNext = () => {
        const newErrors = validateForm(step);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setStep((prev) => prev + 1);
        }
    };

    const handleBack = () => {
        setStep((prev) => prev - 1);
    };

    const validateForm = (currentStep) => {
        const newErrors = {};

        if (currentStep === 1) {
            // Validate step 1 fields
            if (!formData.name.trim()) newErrors.name = "Name is required.";
            if (!formData.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
                newErrors.email = "Valid email is required.";
            if (!formData.contact.trim() || !/^\d+$/.test(formData.contact))
                newErrors.contact = "Contact number is required and should be numeric.";
            if (!formData.degree.trim()) newErrors.degree = "Degree is required.";
            if (!formData.department.trim()) newErrors.department = "Department is required.";
            if (!formData.experience.trim()) newErrors.experience = "Experience is required.";
            if (!formData.currentLocation.trim()) newErrors.currentLocation = "Current location is required.";
            if (!formData.permenantLocation.trim())
                newErrors.permenantLocation = "Permanent location is required.";
        } else if (currentStep === 2 && job.questions?.length > 0) {
            // Validate questions in step 2
            job.questions.forEach((question, index) => {
                if (!formData.answers[index]?.trim()) {
                    newErrors[`question-${index}`] = `Answer to question ${index + 1} is required.`;
                }
            });
        } else if (currentStep === 3 || (currentStep === 2 && job.questions?.length === 0)) {
            // Validate resume upload in step 3
            if (!resume) {
                newErrors.resume = "Resume upload is required.";
            }
        }

        return newErrors;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Form validation
        const newErrors = {};

        // Name validation
        if (!formData.name.trim()) newErrors.name = "Name is required.";

        // Contact validation
        if (!formData.contact) {
            newErrors.contact = "Contact number is required.";
        } else if (!/^\d{10}$/.test(formData.contact)) {
            newErrors.contact = "Enter a valid 10-digit contact number.";
        }

        // Email validation
        if (!formData.email) {
            newErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Enter a valid email address.";
        }

        // Degree validation
        if (!formData.degree.trim()) newErrors.degree = "Degree is required.";

        // Department validation
        if (!formData.department.trim()) newErrors.department = "Department is required.";

        // Dynamic questions validation
        job.questions.forEach((question, index) => {
            if (!formData.answers[index] || !formData.answers[index].trim()) {
                newErrors[`question-${index}`] = "This field is required.";
            }
        });

        // Resume validation
        if (!resume) {
            setError("Resume is required.");
            return;
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return;
        }

        setError("");

        // Toast to indicate loading state
        const toastId = toast.loading("Submitting job details...");

        try {
            const payload = new FormData();
            payload.append("name", formData.name);
            payload.append("contact", formData.contact);
            payload.append("email", formData.email);
            payload.append("degree", formData.degree);
            payload.append("department", formData.department);
            payload.append("experience", formData.experience);
            payload.append("currentLocation", formData.currentLocation);
            payload.append("permenantLocation", formData.permenantLocation);
            payload.append("spreadsheetId", job.spreadsheetId);
            payload.append("parentFolderId", job.parentFolderId);
            payload.append("resumeFolderId", job.resumeFolderId);
            payload.append("resume", resume);
            payload.append("jobTitle", job.jobTitle);
            payload.append("emailAlert", job.emailAlerts);

            // Create questions and answers structure
            const questionsAndAnswers = job.questions.map((question, index) => ({
                question,
                answer: formData.answers[index]
            }));

            console.log("Questions and Answers:", questionsAndAnswers);

            // Append questions and answers as a JSON string
            payload.append("questionsAndAnswers", JSON.stringify(questionsAndAnswers));

            const response = await axios.post(SUBMIT_JOB, payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            console.log("Submission successful:", response.data);

            // Success toast update
            toast.update(toastId, {
                render: "Details submitted successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
            });

            setIsOpen(false);

            setStep(1);

            // Reset form data
            setFormData({
                name: "",
                contact: "",
                email: "",
                degree: "",
                department: "",
                experience: "",
                currentLocation: "",
                permenantLocation: "",
                answers: {},
            });

            // Optionally reset resume
            setResume(null);
        } catch (error) {
            console.error("Error submitting form:", error);

            // Error toast update
            toast.update(toastId, {
                render: "An error occurred while submitting the details.",
                type: "error",
                isLoading: false,
                autoClose: 3000,
            });
        }
    };

    const steps = [
        { title: 'Personal Information', icon: faUser },
        { title: 'Additional Questions', icon: faQuestion, conditional: !!job.questions?.length },
        { title: 'Upload Resume', icon: faFile },
    ];

    return (
        <div>
            <NewNav />
            {job ? (
                <div className="max-w-[1300px] mx-auto p-6 bg-white rounded-lg shadow-lg mb-10">
                    {/* Header Section */}
                    <div className="text-center mb-8">
                        <div className="flex items-center justify-center gap-4 mb-2">
                            <span className="text-gray-600 text-sm">Sun Teknolozy</span>
                            <div className="h-6 w-[1px] bg-gray-400"></div>
                            <span className="text-gray-600 text-sm">Full-Time</span>
                        </div>
                        <h1 className="text-4xl md:text-5xl font-extrabold">{job.jobTitle}</h1>
                        <div className="flex justify-center gap-4 mt-4">
                            <Button
                                color="blue"
                                variant="gradient"
                                size="sm"
                                className="px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                                onClick={() => {
                                    const link = window.location.href; // Fetch the current page URL
                                    const copiedText = `Hay, Sun Teknolozy is looking for ${job.jobTitle}, Check out this link ${link}.`;

                                    // Copy to clipboard
                                    navigator.clipboard.writeText(copiedText).then(() => {
                                        // Display the success toast message
                                        toast.success("Link copied to clipboard! ", {
                                            autoClose: 3000,
                                        });
                                    }).catch(err => {
                                        toast.error("Failed to copy link.", {
                                            autoClose: 3000,
                                        });
                                    });
                                }}
                            >
                                <span className="py-2 text-base">
                                    Refer a Friend
                                </span>
                            </Button>
                            <Button
                                color="green"
                                variant="gradient"
                                size="sm"
                                className="px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                                onClick={openPopup}
                            >
                                <span className="py-2 text-base">
                                    I'm Interested
                                </span>
                            </Button>
                        </div>
                    </div>

                    {/* Divider */}
                    <hr className="my-8 border-gray-300" />

                    {/* Content Section */}
                    <div className="max-w-[1300px] mx-auto p-6">
                        {/* Left Section: Job Description */}
                        <div className="col-span-2">
                            {job.jobDescription && (
                                <>
                                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Job Description</h2>
                                    <p className="text-lg text-gray-700 mb-6">{job.jobDescription}</p>
                                </>
                            )}

                            <div className="grid grid-cols-1 md:grid-cols-5 gap-x-8 mb-6">
                                {job.jobType && (
                                    <div>
                                        <p className="text-md text-gray-600 font-medium mb-2">Job Type</p>
                                        <p className="text-lg text-gray-800">{job.jobType}</p>
                                    </div>
                                )}
                                {job.location && (
                                    <div>
                                        <p className="text-md text-gray-600 font-medium mb-2">Location</p>
                                        <p className="text-lg text-gray-800">{job.location}</p>
                                    </div>
                                )}
                                {job.salary && (
                                    <div>
                                        <p className="text-md text-gray-600 font-medium mb-2">Salary</p>
                                        <p className="text-lg text-gray-800">{job.salary}</p>
                                    </div>
                                )}
                                {job.experience && (
                                    <div>
                                        <p className="text-md text-gray-600 font-medium mb-2">Experience</p>
                                        <p className="text-lg text-gray-800">{job.experience}</p>
                                    </div>
                                )}
                                {job.numberOfOpenings && (
                                    <div>
                                        <p className="text-md text-gray-600 font-medium mb-2">Openings</p>
                                        <p className="text-lg text-gray-800">{job.numberOfOpenings}</p>
                                    </div>
                                )}
                            </div>

                            {/* Educational Qualification */}
                            {job.educationalQualification && (
                                <div className="mb-6">
                                    <h3 className="text-xl font-semibold text-gray-800 mb-3">Educational Qualification</h3>
                                    <p className="text-lg text-gray-700">{job.educationalQualification}</p>
                                </div>
                            )}

                            {job.preferences && job.preferences.length > 0 && (
                                <div className="mb-6">
                                    <h3 className="text-xl font-semibold text-gray-800 mb-3">Preference</h3>
                                    <ul className="list-disc pl-6 space-y-2 text-lg text-gray-700">
                                        {job.preferences.map((preference, index) => (
                                            <li key={index}>{preference}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {/* Skills */}
                            {job.skills && job.skills.length > 0 && (
                                <div className="mb-6">
                                    <h3 className="text-xl font-semibold text-gray-800 mb-3">Skills</h3>
                                    <ul className="list-disc pl-6 space-y-2 text-lg text-gray-700">
                                        {job.skills.map((skill, index) => (
                                            <li key={index}>{skill}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {/* Key Responsibilities */}
                            {job.keyResponsibilities && job.keyResponsibilities.length > 0 && (
                                <div className="mb-6">
                                    <h3 className="text-xl font-semibold text-gray-800 mb-3">Key Responsibilities</h3>
                                    <ul className="list-disc pl-6 space-y-2 text-lg text-gray-700">
                                        {job.keyResponsibilities.map((task, index) => (
                                            <li key={index}>{task}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <p className="text-center text-xl text-gray-500 mt-8">Job not found.</p>
            )}

            {/* Popup Dialog */}
            <Dialog open={isOpen} handler={setIsOpen} size="lg" className="rounded-lg shadow-xl bg-white">
                <DialogHeader className="text-xl font-bold text-gray-800 flex items-center gap-2">
                    Job Application, <span className="text-green-500 material-icons">{job.jobTitle}</span>
                </DialogHeader>

                {/* Responsive Stepper */}
                <div className="flex flex-col items-center w-full px-16">
                    <div className="flex items-center justify-between w-full">
                        {steps.map((item, index) => {
                            const isCompleted = step > index + 1;
                            const isActive = step === index + 1;

                            if (item.conditional === false) return null;

                            return (
                                <div key={index} className="flex items-center">
                                    {/* Step Icon and Title */}
                                    <div className="flex flex-col items-center">
                                        <div
                                            className={`relative w-10 h-10 flex items-center justify-center rounded-full 
                                ${isCompleted ? 'bg-green-500 text-white' : isActive ? 'bg-green-100 text-green-500' : 'bg-gray-300 text-gray-500'}
                            `}
                                        >
                                            <FontAwesomeIcon icon={item.icon} className="text-xl" />
                                        </div>
                                        <div className="mt-2 text-sm text-center text-gray-700">
                                            {item.title}
                                        </div>
                                    </div>
                                    {/* Line Connector */}
                                    {index < steps.length - 1 && (
                                        <div
                                            className={`h-0.5 ${isCompleted ? 'bg-green-500' : 'bg-gray-300'}`}
                                            style={{
                                                flexGrow: 1, // Ensures the line stretches between steps
                                                height: '2px', // Sets a clear height for visibility
                                                marginLeft: '10px', // Optional margin for spacing
                                                marginRight: '10px', // Optional margin for spacing
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>

                <DialogBody className="bg-gray-50 p-6 rounded-lg">
                    {step === 1 && (
                        <>
                            {/* <p className="text-lg font-medium text-gray-700 mb-6 border-b pb-2">
                                Personal Information
                            </p> */}
                            <form>
                                <div className="grid grid-cols-1 gap-6">
                                    {/* Name Field */}
                                    <div>
                                        <Input
                                            required
                                            label="Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            error={!!errors.name}
                                            className="border-gray-300 rounded-lg focus:ring-blue-500"
                                        />
                                        {errors.name && (
                                            <p className="text-sm text-red-500">{errors.name}</p>
                                        )}
                                    </div>



                                    {/* Contact Number and Email */}
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        {['contact', 'email'].map((field, index) => (
                                            <div key={index}>
                                                <Input
                                                    required
                                                    label={field === 'contact' ? 'Contact Number' : 'Email'}
                                                    name={field}
                                                    value={formData[field]}
                                                    onChange={handleChange}
                                                    error={!!errors[field]}
                                                    className="border-gray-300 rounded-lg focus:ring-blue-500"
                                                />
                                                {errors[field] && (
                                                    <p className="text-sm text-red-500">{errors[field]}</p>
                                                )}
                                            </div>
                                        ))}
                                    </div>

                                    {/* Current and Permanent Location */}
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        {['currentLocation', 'permenantLocation'].map((field, index) => (
                                            <div key={index}>
                                                <Input
                                                    required
                                                    label={
                                                        field === 'currentLocation'
                                                            ? 'Current Location'
                                                            : 'Permanent Location'
                                                    }
                                                    name={field}
                                                    value={formData[field]}
                                                    onChange={handleChange}
                                                    error={!!errors[field]}
                                                    className="border-gray-300 rounded-lg focus:ring-blue-500"
                                                />
                                                {errors[field] && (
                                                    <p className="text-sm text-red-500">{errors[field]}</p>
                                                )}
                                            </div>
                                        ))}
                                    </div>

                                    {/* Degree, Department, Experience */}
                                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                                        {['degree', 'department', 'experience'].map((field, index) => (
                                            <div key={index}>
                                                <Input
                                                    required
                                                    label={field.charAt(0).toUpperCase() + field.slice(1)}
                                                    name={field}
                                                    value={formData[field]}
                                                    onChange={handleChange}
                                                    error={!!errors[field]}
                                                    className="border-gray-300 rounded-lg focus:ring-blue-500"
                                                />
                                                {errors[field] && (
                                                    <p className="text-sm text-red-500">{errors[field]}</p>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </form>
                        </>
                    )}

                    {step === 2 && job.questions?.length > 0 && (
                        <>
                            {/* <p className="text-lg font-medium text-gray-700 mb-6 border-b pb-2">
                                Answer the Following Questions
                            </p> */}
                            {job.questions.map((question, index) => (
                                <div key={index} className="mb-4">
                                    <Textarea
                                        required
                                        name={`question-${index}`}
                                        label={question}
                                        value={formData.answers[index] || ''}
                                        onChange={(e) =>
                                            handleAnswerChange(index, e.target.value)
                                        }
                                        className="border border-gray-300 rounded-lg focus:ring-blue-500"
                                        error={!!errors[`question-${index}`]}
                                    />
                                    {errors[`question-${index}`] && (
                                        <p className="text-sm text-red-500">
                                            {errors[`question-${index}`]}
                                        </p>
                                    )}
                                </div>
                            ))}
                        </>
                    )}

                    {((step === 2 && job.questions?.length === 0) || step === 3) && (
                        <>
                            <div className="mb-4">
                                <FileUploader
                                    handleChange={handleResumeChange}
                                    name="resume"
                                    types={fileTypes}
                                    maxSize={5}
                                    hoverTitle="Drop your resume here"
                                    classes="border border-gray-300 p-2 w-full rounded-lg focus:ring-blue-500 drop_area drop_zone"
                                />
                                {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
                                {resume && (
                                    <p className="text-sm text-green-500 mt-2">
                                        {resume.name} uploaded successfully!
                                    </p>
                                )}
                            </div>
                        </>
                    )}
                </DialogBody>
                <DialogFooter className="bg-gray-100 p-4 rounded-b-lg flex justify-between items-center">
                    <Button variant="text" color="red" onClick={closePopup}>
                        Cancel
                    </Button>
                    <div className="flex space-x-2">
                        {step > 1 && (
                            <Button variant="text" color="blue" onClick={handleBack}>
                                Previous
                            </Button>
                        )}
                        {step < steps.length && (
                            <Button variant="gradient" color="green" onClick={handleNext}>
                                Next
                            </Button>
                        )}
                        {step === steps.length && (
                            <Button variant="gradient" color="green" onClick={handleSubmit}>
                                Submit Application
                            </Button>
                        )}
                    </div>
                </DialogFooter>
            </Dialog>

            <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />


            <Address />
        </div>
    );
};

export default JobDetail;
