import React from 'react'
import BusinessVerticalCard from './BusinessVerticalCard';
import air_img from '../../../assets/C_Air.jpg';
import water_img from '../../../assets/C_water_quality.jpg';
import industrial_img from '../../../assets/C_Industrial_health.jpg';
import biotech_img from '../../../assets/C-Biotech.jpg';

const BusinessVerticals = () => {
  const airQaulityLink = "/products/Air Quality";
  const waterQualityLink = "/products/WaterQuality";
  const occupationalHealthIndustrialHygieneLink = "/products/OccupationalHealthIndustrialHygiene";
  const biotechLink = "/products/Pharma and Bio-Tech";
  return (
    <div className='2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-6'>
        <div className='h-full flex flex-col justify-center items-center'>
        <h1 className="container lg:pl-0 lg:pr-0 text-2xl font-extrabold leading-10 tracking-tight
         mb-3 text-gray-90 sm:leading-10 
         items-center text-center md:text-4xl 
         md:leading-normal">
            Our<span className=" pl-4 text-green-500 font-medium">Portfolios</span>
          </h1>
            <div className="pt-12 grid sm:grid-cols-2 grid-cols-2 sm:gap-8 gap-4">
            {/* Card 1 */}
            <BusinessVerticalCard title="Water Quality" imgSrc={water_img} link={waterQualityLink} />
            {/* Card 2 */}
            <BusinessVerticalCard title="Air Quality" imgSrc={air_img} link={airQaulityLink}/>
            {/* Card 3 */}
            <BusinessVerticalCard title="Occupational Health and Industrial Hygiene" imgSrc={industrial_img} link={occupationalHealthIndustrialHygieneLink}/>
            {/* Card 4 */}
            <BusinessVerticalCard title="Pharma/Biotech" imgSrc={biotech_img} link={biotechLink}/>
        </div>
        </div>

    </div>
  )
}

export default BusinessVerticals;