import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SENSOR_DATA_API } from "./Api";
import axios from "axios";

class Result {
    constructor({ param_id, param_name, unit_name, reading_value, reading_timestamp }) {
        this.param_id = param_id;
        this.param_name = param_name;
        this.unit_name = unit_name;
        this.reading_value = reading_value;
        this.reading_timestamp = reading_timestamp;
    }
}


const CenterModeCarousel = () => {
    const sliderRef = useRef(null);
    const [result, setResult] = useState([]);

    useEffect(() => {
        const fetchReadingsForParameters = async () => {
            try {
                const response = await axios.get(SENSOR_DATA_API);
                const data = response.data;
                const resultList = data.map((item) => new Result(item));
                setResult(resultList);
                console.log(resultList);
            } catch (error) {
                console.error('Error fetching sensor data:', error);
            }
        };

        fetchReadingsForParameters();

        const intervalId = setInterval(fetchReadingsForParameters, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);


    const settings = {
        className: "center",
        centerMode: true,
        centerPadding: "30px",
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 10,
        autoplay: true,
        autoplaySpeed: 3500,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "30px",
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "40px",
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "20px",
                },
            },
        ],
    };

    return (
        <div className="slider-container relative w-full">
            <Slider ref={sliderRef} {...settings}>
                {result.map((result, index) => (
                    <div key={index} className="p-4 flex justify-center items-center">
                        <div className="container mx-auto px-6 py-8 bg-transparent rounded-lg text-left justify-center flex items-center">
                            {/* Reading Value */}
                            <p className="text-8xl font-bold text-black">{Math.round(result.reading_value)}</p>
    
                            {/* Vertical Line and Unit/Parameter in Column */}
                            <div className="ml-2 flex flex-col justify-center">
                                {/* <span className="text-gray-300 text-4xl font-light">|</span> */}
                            </div>
    
                            {/* Unit and Parameter Name (Stacked) */}
                            <div className="ml-2 flex flex-col">
                                <span className="text-black-300 text-xl font-medium">{result.unit_name}</span>
                                <span className="text-black-400 text-sm">{result.param_name}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
    
            <style jsx>{`
                @keyframes blink {
                    0%, 100% {
                        opacity: 1;
                    }
                    50% {
                        opacity: 0;
                    }
                }
            
                .blink {
                    animation: blink 2s linear infinite;
                    color: #4caf50;
                }
            `}</style>
        </div>
    );
    
    
};

export default CenterModeCarousel;