import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function HeroSection() {
  const words = ["Products.", "Clients.", "Categories.", "Testimonials.", "Product Manual.", "Careers."];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <div className="w-full flex flex-col items-start justify-center px-4 sm:px-8">
      <span className="font-bold text-gray-700 font-poppins break-words leading-tight 
                      text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-[120px]">
        Take Control of Your Site{" "}
        <AnimatePresence mode="wait">
          <motion.span
            key={index}
            className="text-green-500"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            {words[index]}
          </motion.span>
        </AnimatePresence>
      </span>
    </div>
  );
}
