import React from "react";
import NewNav from "../navbar/NewNav";
import ProductListByCategory from "./productList/ProductList_by_Category";
import Address from "../address/Address";

// Products page
const Products = () => {

  return (
    <div>
      <NewNav />
      <ProductListByCategory />
      <Address />
    </div>
  );
};

export default Products;
