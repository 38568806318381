import React, { useState } from "react";
import { Input, Textarea } from "@material-tailwind/react";
import { MdClose, MdEdit } from "react-icons/md";
import axios from "axios";
import { UPLOAD_PRODUCT_API } from "../../Api";

const AddProduct = ({ categoryId, subCategoryId, onClose }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [feature, setFeature] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleAddProduct = async () => {
    const formData = new FormData();
    formData.append("productName", name);
    formData.append("productDescription", description);
    formData.append("productFeatures", feature);
    formData.append("categoryId", categoryId);
    formData.append("subCategoryId", subCategoryId);

    if (image) {
      formData.append("productImages", image);
    }

    try {
      const response = await axios.post(UPLOAD_PRODUCT_API, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("Product added successfully:", response.data);
      onClose();
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  return (
    <div className="fixed inset-0 z-[1000] flex">
      <div className="hidden sm:block flex-grow bg-black bg-opacity-50" onClick={onClose}></div>
      <div className="relative h-full w-full sm:w-[650px] bg-white shadow-lg flex flex-col">
        <button className="absolute top-4 right-4 text-gray-600 hover:text-red-500" onClick={onClose}>
          <MdClose className="w-6 h-6" />
        </button>
        <div className="flex-1 overflow-y-auto p-6" style={{ maxHeight: "calc(100vh - 60px)" }}>
        <h2 className="text-lg font-bold text-gray-900 mb-4">
  {categoryId} &gt; {subCategoryId} &gt; {name || "Add Product"}
</h2>

          <hr className="mb-4 border-gray-300" />

          <div className="relative group w-full h-48 rounded-lg overflow-hidden shadow-md mb-4 bg-gray-200 flex items-center justify-center">
  {imagePreview ? (
    <img
      src={imagePreview}
      alt="Product"
      className="w-full h-full object-cover transition-transform duration-300 transform scale-100 group-hover:scale-105"
    />
  ) : null}

  {/* Always show the overlay if no image is selected */}
  <div className={`absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-300 cursor-pointer ${!imagePreview ? "opacity-100" : "opacity-0 group-hover:opacity-100"}`}>
    <label htmlFor="imageUpload" className="text-white flex flex-col items-center">
      <MdEdit className="w-8 h-8" />
      <span className="text-sm mt-1">Upload Image</span>
    </label>
    <input
      id="imageUpload"
      type="file"
      accept="image/*"
      className="hidden"
      onChange={handleImageChange}
    />
  </div>
</div>


          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-1">Product Name</label>
            <Input 
              type="text" 
              value={name} 
              className="border border-gray-300 p-2 rounded-lg" 
              onChange={(e) => setName(e.target.value)} 
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-1">Description</label>
            <Textarea
              className="w-full border border-gray-300 p-2 rounded-lg"
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></Textarea>
          </div>

          <div className="mb-4">
  <label className="block text-gray-700 font-semibold mb-1">Features</label>
  <Textarea
    className="w-full border border-gray-300 p-2 rounded-lg"
    rows={5}
    value={feature}
    onChange={(e) => setFeature(e.target.value)}
  ></Textarea>
</div>
        </div>

        <div className="absolute bottom-0 left-0 w-full bg-white shadow-md flex px-4 py-4 gap-3 border-t border-gray-200">
          <button className="flex-1 bg-gray-400 text-white py-3 rounded-lg hover:bg-gray-500" onClick={onClose}>
            Cancel
          </button>
          <button className="flex-1 bg-green-500 text-white py-3 rounded-lg hover:bg-green-600" onClick={handleAddProduct}>
            Add Product
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
