import { Input } from "@material-tailwind/react";
import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import SuccessMsg from "../adminPage/AddProductComponents/SuccessMsg";
import ErrorMsg from "../adminPage/AddProductComponents/ErrorMsg";
import { ENQUIRE_NOW_API } from "../Api";
import axios from "axios";

const EnquireForm = ({ productName, onCancel }) => {
  const [orgName, setOrgName] = useState("");
  const [personName, setPersonName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [orgEmail, setOrgEmail] = useState("");
  const [description, setDescription] = useState("");
  // const [modelNumber, setModelNumber] = useState("");
  const [enquiryType, setEnquiryType] = useState(null);
  const [errorMsg, setErrorMsg] = useState(""); // Error message
  const [errPopUpMsg, setErrPopUpMsg] = useState(false); // Error Pop up message
  const [successPopUpMsg, setSuccessPopUpMsg] = useState(false); // Success Pop up message
  const form = useRef();
  const [selectedDivBtn, setSelectedDivBtn] = useState(); // to change the color of selected enquiryType button
  const divisions = ["Sales", "Service"];

  const handleOrgNameChange = (e) => {
    setOrgName(e.target.value);
  };
  const handlePersonNameChange = (e) => {
    setPersonName(e.target.value);
  };
  const handleContactNumberChange = (e) => {
    setContactNumber(e.target.value);
  };
  const handleOrgEmailChange = (e) => {
    setOrgEmail(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  // const handleModelNumberChange = (e) => {
  //   setModelNumber(e.target.value);
  // };

  const handleEnquiryTypeChange = (selectedDivision, index) => {
    console.log("enquiryType selected: ", selectedDivision);
    if (selectedDivBtn === index) {
      setEnquiryType(null);
      setSelectedDivBtn(null);
    } else {
      setEnquiryType(selectedDivision);
      setSelectedDivBtn(index);
    }
  };
  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var tempErrMsg = "";
    if (orgName === "") {
      tempErrMsg = tempErrMsg + "Please fill Organization Name field. \t\n";
    }
    if (personName === "") {
      tempErrMsg = tempErrMsg + "Please fill Person Name field. \t\n";
    }
    if (
      contactNumber === "" ||
      contactNumber.length < 10 ||
      contactNumber.length > 10
    ) {
      tempErrMsg = tempErrMsg + "Please Check Contact Number field. \t\n";
    }
    if (
      orgEmail === "" ||
      !orgEmail.includes("@") ||
      !orgEmail.includes(".") ||
      orgEmail.includes(" ")
    ) {
      tempErrMsg = tempErrMsg + "Please Check Email field. \t\n";
    }
    if (description === "") {
      tempErrMsg = tempErrMsg + "Please fill Description field. \t\n";
    }
    if (enquiryType == null) {
      tempErrMsg = tempErrMsg + "Please select the enquiry type. \t\n";
    }
    // if (enquiryType == "Service" && selectedProdName == "") {
    //   tempErrMsg = tempErrMsg + "Please fill Product Name field. \t\n";
    // }
    // if (enquiryType == "Warranty" && selectedProdName == "") {
    //   tempErrMsg = tempErrMsg + "Please fill Product Name field. \t\n";
    // }

    if (tempErrMsg !== "") {
      console.log("Error message: ", tempErrMsg);
      setErrorMsg(tempErrMsg);
      setErrPopUpMsg(true);
      return;
    }
    // Send email
    const formData = new FormData();
    formData.append("orgName", orgName);
    formData.append("personName", personName);
    formData.append("orgEmail", orgEmail);
    formData.append("contactNumber", contactNumber);

      formData.append("enquiryType", enquiryType);

      formData.append("productName", productName);

      formData.append("productName", productName);

    formData.append("description", description);
    try {
      const response = await axios.post(ENQUIRE_NOW_API, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status >= 200) {
        console.log("Form submitted successfully:", response.data);
        setSuccessPopUpMsg(true);
      } else {
        console.log("Error submitting form:", response.data);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        onCancel();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [onCancel]);

  const getClasses = (index) =>
    classNames("cursor-pointer", {
      // "bg-green-200": selectedDivBtn == index,
      // "bg-gray-100":
      // selectedDivBtn !== null && selectedDivBtn !== index,
      "font-bold": selectedDivBtn === index,
      "font-normal": selectedDivBtn !== null && selectedDivBtn !== index,
    });

  return (
    <div>
      <div className="fixed z-50 inset-0 overflow-y-auto bg-gray-800 bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-8 overflow-y-auto flex flex-col justify-between h-4/5 rounded-xl shadow-md">
          {successPopUpMsg && (
            <SuccessMsg
              msg="Form submission successful! We will contact you soon."
              onClose={() => {
                setSuccessPopUpMsg(false);
                onCancel();
              }}
            />
          )}
          {errPopUpMsg && (
            <ErrorMsg
              errMsg={errorMsg}
              onClose={() => {
                setErrPopUpMsg(false);
              }}
            />
          )}
          <h2 className="text-md md:text-md lg:text-lg font-semibold mb-4">
            Please Fill out this form,
            <span className="text-green-500">
              {" "}
              Our team will contact you soon!{" "}
            </span>
          </h2>
          <form id="enquiry-form" ref={form}>
            <Input
              name="orgName"
              type="text"
              required
              size="md"
              label="Organization Name"
              onChange={handleOrgNameChange}
              className="border border-gray-300 p-2 w-full"
            />
            <br />
            <Input
              name="personName"
              type="text"
              required
              size="md"
              label="Your Name"
              onChange={handlePersonNameChange}
              className="border border-gray-300 p-2 w-full"
            />
            <br />

            <Input
              name="contactNumber"
              type="number"
              required
              size="md"
              label="Contact Number"
              onChange={handleContactNumberChange}
              className="border border-gray-300 p-2 w-full"
            />
            <br />
            <Input
              name="orgEmail"
              type="email"
              required
              size="md"
              label="E-mail"
              onChange={handleOrgEmailChange}
              className="border border-gray-300 p-2 w-full"
            />
            <br />

            <div>
              <p className="text-sm text-center text-gray-700">
                Select enquiry type <span className=" text-red-600 pl-0.5"> *</span>
              </p>
            </div>
            <br />
            <div className=" w-full flex flex-row justify-around align-middle">
              {divisions.map((division, index) => {
                return (
                  <div
                    key={index}
                    className={`${getClasses(index)}
                      w-1/3 m-1 px-2 py-2 flex flex-col justify-center items-middle rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg h-auto hover:cursor-default transform transition-all hover:scale-110`}
                    onClick={() => {
                      handleEnquiryTypeChange(division, index);
                    }}
                  >
                    <p className="text-center text-md">{division}</p>
                  </div>
                );
              })}
            </div>
            <br />
            {/* <input
                name="senderEmail"
                type="email"
                value={enquiryType}
                className="hidden"
              /> */}
            {/* <div className={`${selectedDivBtn != 0 ? "hidden" : "block"}`}>
              <Input
                name="modelNumber"
                type="text"
                required
                size="md"
                label="Model Number"
                onChange={handleModelNumberChange}
                className="border border-gray-300 p-2 w-full"
              />
            </div>
            <br /> */}

            <div className="relative w-full min-w-[200px]">
              <textarea
                className="peer h-full min-h-[100px] w-full resize-none rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
                name="description"
                type="text"
                placeholder=""
                required
                rows={5}
                onChange={handleDescriptionChange}
              ></textarea>
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Description / Usecases{" "}
                <span className=" text-red-600 pl-0.5"> *</span>
              </label>
            </div>
            <br />

            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-green-500 text-white rounded mr-4"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnquireForm;
