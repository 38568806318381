import React, { useEffect, useState } from "react";
import { Input } from "@material-tailwind/react";
import { IoIosAddCircleOutline } from "react-icons/io";
import DeleteConfirmation from "./DeleteConfirmation";
import Navbar from "../AdminNav";
import { TrashIcon } from "@heroicons/react/24/outline";
import { MdClose, MdCloudUpload } from "react-icons/md";
import { DELETE_CLIENT_API, FETCH_CLIENTS_API, UPLOAD_CLIENT_API } from "../../Api";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

const AddClients = () => {
  const navigate = useNavigate();
  const [clientsData, setClientsData] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [search, setSearch] = useState("");
  const [addClient, setAddClient] = useState(false);
  const [selectedClientName, setSelectedClientName] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientImage, setClientImage] = useState(null);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken || jwtDecode(authToken).exp < Date.now() / 1000) {
      navigate("/signin");
    }
  }, [navigate]);

  const fetchClients = async () => {
    try {
      const response = await fetch(FETCH_CLIENTS_API);
      const data = await response.json();
      setClientsData(data);
      setFilteredClients(data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleDelete = async (selectedClientName) => {
    if (!selectedClientName) return;
    try {
      await fetch(DELETE_CLIENT_API + selectedClientName, { method: "DELETE" });
      setShowDeleteConfirmation(false);
      setSelectedClientName(null);
      fetchClients();
    } catch (error) {
      console.error("Error deleting client:", error);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setFileName(file.name);
      setClientImage(file);
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setClientImage(file);
    }
  };

  useEffect(() => {
    if (!search) {
      setFilteredClients(clientsData);
    } else {
      const filtered = clientsData.filter((client) =>
        client.name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredClients(filtered);
    }
  }, [search, clientsData]);

  const handleAddClient = async () => {
    if (!clientName || !clientImage) {
      alert("Please enter a client name and upload an image.");
      return;
    }

    const formData = new FormData();
    formData.append("clientName", clientName);
    formData.append("clientLogo", clientImage);

    try {
      await axios.post(UPLOAD_CLIENT_API, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      fetchClients();
      setAddClient(false);
      setClientName("");
      setClientImage(null);
      setFileName("");
    } catch (error) {
      console.error("Error uploading client:", error);
    }
  };

  return (
    <div className="relative">
      <Navbar />

      {showDeleteConfirmation && (
        <DeleteConfirmation
          onConfirm={() => handleDelete(selectedClientName)}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}

      {/* Add Client Slide Panel */}
      <div
        className={`fixed top-0 right-0 h-full w-[500px] bg-white shadow-lg p-6 transform ${
          addClient ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-500 ease-in-out z-50`}
      >
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-red-500"
          onClick={() => setAddClient(false)}
        >
          <MdClose className="w-6 h-6" />
        </button>

        <h2 className="text-2xl font-bold text-gray-900">Add Clients</h2>
        <hr className="my-2 border-gray-300" />

<div className="my-4">
        <Input
          label="Enter Client Name"
          type="text"
          required
          className="border border-gray-300 p-2 w-full"
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
        />
             </div>

        <label className="text-gray-700 text-sm font-semibold">Upload Image</label>
        <div
          className="mt-2 w-full border-2 border-dashed border-gray-300 rounded-lg p-6 flex flex-col items-center justify-center text-gray-600 cursor-pointer hover:border-green-500 hover:bg-green-50"
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
          onClick={() => document.getElementById("fileInput").click()}
        >
          <MdCloudUpload className="text-4xl text-gray-400 mb-2" />
          <p className="text-sm font-semibold">
            {fileName ? fileName : "Drag & Drop or Click to Upload"}
          </p>
          <input id="fileInput" type="file" className="hidden" onChange={handleFileSelect} />
        </div>

        <button className="w-full mt-6 h-10 bg-green-500 hover:bg-green-600 text-white rounded-lg" onClick={handleAddClient}>
          Add Client
        </button>
      </div>

      {/* Header Section */}
      <div className="px-4 md:px-6 mt-8 mb-4">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
          {/* Title */}
          <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900">
            Manage Clients
          </h2>

          {/* Controls (Search & Add Button) */}
          <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 w-full md:w-auto space-y-3 sm:space-y-0">
            {/* Add Client Button */}
            <div
              className="w-full sm:w-48 h-10 flex items-center justify-center bg-green-500 hover:bg-green-600 text-white rounded-lg cursor-pointer transition-all"
              onClick={() => setAddClient(true)}
            >
              <IoIosAddCircleOutline className="w-5 h-5 mr-2" />
              <p className="text-center text-sm lg:text-md">Add Client</p>
            </div>

            {/* Search Input - Responsive Width */}
            <div className="w-full max-w-lg sm:max-w-sm md:max-w-md lg:max-w-lg transition-all duration-300">
              <Input
                label="Search Clients..."
                type="text"
                className="w-full"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Clients Grid */}
      <div className="m-6 grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pt-4">
        {filteredClients.length > 0 ? (
          filteredClients.map((client, index) => (
            <div
              key={client.id}
              className="relative flex flex-col bg-white rounded-xl shadow-md p-2 border border-gray-300 cursor-pointer transition-all transform hover:scale-105 hover:shadow-lg group"
            >
              {/* Row 1: Serial Number & Delete Icon */}
              <div className="flex justify-between items-center px-2 mt-2">
                <span className="text-sm font-bold text-gray-500">
                  {String(index + 1).padStart(2, "0")}
                </span>

                <button
                  className="text-red-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedClientName(client.name);
                    setShowDeleteConfirmation(true);
                  }}
                >
                  <TrashIcon className="w-5 h-5" />
                </button>
              </div>

              {/* Row 2: Client Name */}
              <p className="text-[18px] font-bold text-gray-900 font-poppins  px-2">
                {client.name.toLowerCase()}
              </p>


              {/* Row 3: Logo as Background */}
              <div
                className="relative w-full h-[150px] mt-2 rounded-lg bg-cover bg-center overflow-hidden"
                style={{ backgroundImage: `url(${client.imgSrc})` }}
              >
                {/* Black Overlay */}
                <div className="absolute inset-0 bg-black/50"></div>
              </div>

            </div>
          ))
        ) : (
          <p className="text-center text-gray-500 col-span-full">No clients found.</p>
        )}
      </div>
    </div>
  );
};

export default AddClients;
