import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom'; // Added useNavigate
import axios from "axios";
import { TailSpin } from 'react-loader-spinner';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan, faArrowLeft, faFile, faFolder, faUsersLine, faClock, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import { GET_CAREER_JOBS } from "../../Api";
import { DELETE_JOB } from "../../Api";

const JobDetail = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line
    const [isJobEnabled, setIsJobEnabled] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const jobId = queryParams.get('jobid');
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchJobs = async () => {
            try {
                const response = await axios.get(GET_CAREER_JOBS, {
                    params: {
                        userSide: 'admin',
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setJobs(response.data.jobs);
                const job = response.data.jobs.find(job => job.id === parseInt(jobId));
                if (job) {
                    setIsJobEnabled(job.jobStatus);
                }
            } catch (error) {
                console.error('Error fetching jobs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchJobs();
    }, [jobId]);

    const job = jobs.find(job => job.id === parseInt(jobId));

    // const handleToggle = async (jobTitle) => {
    //     const toastId = toast.loading(`${isJobEnabled ? 'Disabling' : 'Enabling'} the job ${jobTitle}`);

    //     const newJobEnabledState = !isJobEnabled;

    //     try {
    //         const response = await fetch(JOB_STATUS, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 jobTitle: jobTitle,
    //                 isJobEnabled: newJobEnabledState,
    //             }),
    //         });

    //         if (!response.ok) {
    //             throw new Error('Failed to update job status');
    //         }

    //         toast.update(toastId, {
    //             render: `Job ${newJobEnabledState ? 'enabled' : 'disabled'} successfully!`,
    //             type: 'success',
    //             isLoading: false,
    //             autoClose: 3000,
    //         });

    //         setIsJobEnabled(newJobEnabledState);
    //     } catch (error) {
    //         console.error(error);
    //         toast.update(toastId, {
    //             render: 'Error occurred while updating the job status!',
    //             type: 'error',
    //             isLoading: false,
    //             autoClose: 3000,
    //         });
    //     }
    // };

    const handleDeleteClick = () => {
        setShowModal(true);
    };


    const handleDeleteConfirm = async (jobTitle) => {
        console.log("Delete action confirmed");

        try {
            // Show loading toast
            const loadingToast = toast.loading("Deleting job...");

            const response = await fetch(`${DELETE_JOB}/${jobTitle}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                toast.update(loadingToast, {
                    render: `Job titled '${jobTitle}' deleted successfully.`,
                    type: "success",
                    isLoading: false,
                    autoClose: 3000,
                });
                setShowModal(false);
                navigate('/admincareer');
            } else {
                const errorData = await response.json();
                toast.update(loadingToast, {
                    render: `Failed to delete the job: ${errorData.message}`,
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                });
            }
        } catch (error) {
            toast.error("An unexpected error occurred. Please check your connection and try again.");
            console.error("An error occurred while deleting the job:", error);
        }
    };



    const handleCancel = () => {
        setShowModal(false);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <TailSpin
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                />
            </div>
        );
    }



    return (
        <div>
            {job ? (
                <div className="max-w-[1300px] mx-auto p-6 bg-white p-6 rounded-lg shadow-lg mb-10">
                    {/* Header Controls Section */}
                    <div className="flex justify-between items-center mb-8 mt-4 flex-wrap"> {/* Added flex-wrap for better wrapping on smaller screens */}
                        {/* Go Back Button */}
                        <Link
                            to="/admincareer"
                            className="flex items-center space-x-2 bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300 shadow-md transform hover:scale-105 w-full sm:w-auto mb-4 sm:mb-0"
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                            <span>Go Back</span>
                        </Link>

                        {/* Right side (Toggle, Edit, Delete, Sheets, Resumes buttons) */}
                        <div className="flex items-center space-x-4 flex-wrap sm:flex-nowrap w-full sm:w-auto"> {/* Added flex-wrap for smaller screens */}

                            {/* Toggle Switch */}
                            

                            {/* Sheets Button */}
                            <Link
                                to={`https://docs.google.com/spreadsheets/d/${job.spreadsheetId}`}
                                className="relative flex items-center space-x-2 border-2 border-black text-black px-4 py-2 rounded-lg transition-all duration-300 shadow-md transform hover:scale-105 group w-full sm:w-auto mb-4 sm:mb-0"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {/* Icon and Text */}
                                <FontAwesomeIcon icon={faFile} className="text-xl" />
                                <span className="text-sm font-medium ml-2 hidden group-hover:inline-block transition-all duration-300">
                                    Open Sheets
                                </span>

                                {/* Red Circle with Count */}
                                <div className="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center -mt-3 -mr-4 border-2 border-white">
                                    {job.range.replace(/\D/g, '') - 2}
                                </div>
                            </Link>

                            {/* Resumes Button */}
                            <Link
                                to={`https://drive.google.com/drive/folders/${job.resumeFolderId}`}
                                className="flex items-center space-x-2 border-2 border-black text-black px-4 py-2 rounded-lg transition-all duration-300 shadow-md transform hover:scale-105 group w-full sm:w-auto mb-4 sm:mb-0"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faFolder} className="text-xl" />
                                <span className="text-sm font-medium ml-2 hidden group-hover:inline-block transition-all duration-300">
                                    Open Resumes
                                </span>
                            </Link>

                            {/* Edit Button */}
                            <Link
                                to={`/admincareer/addcareer?jobid=${job.id}`}
                                className="flex items-center space-x-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300 shadow-md transform hover:scale-105 w-full sm:w-auto mb-4 sm:mb-0"
                            >
                                <FontAwesomeIcon icon={faPenToSquare} />
                                <span>Edit</span>
                            </Link>

                            {/* Delete Button */}
                            <button
                                className="flex items-center space-x-2 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300 shadow-md transform hover:scale-105 w-full sm:w-auto"
                                onClick={handleDeleteClick}
                            >
                                <FontAwesomeIcon icon={faTrashCan} />
                                <span>Delete</span>
                            </button>

                        </div>
                    </div>




                    {/* Header Section */}
                    <div className="text-center mb-4">
                        <h1 className="text-4xl md:text-5xl font-extrabold text-gray-800">{job.jobTitle}</h1>
                    </div>

                    <div className="flex justify-center space-x-10 mb-8">
                        {/* Job Created Date */}
                        <div className="flex items-center space-x-2 text-gray-600">
                            <FontAwesomeIcon icon={faClock} size="lg" className="text-blue-500" />
                            <span className="text-lg font-medium">
                                {(() => {
                                    const createdDate = new Date(job.createdAt._seconds * 1000);
                                    const currentDate = new Date();
                                    const diffTime = currentDate - createdDate; // Difference in milliseconds
                                    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Difference in days

                                    if (diffDays === 0) {
                                        return 'Today';
                                    } else if (diffDays === 1) {
                                        return 'Posted 1 day ago';
                                    } else {
                                        return `Posted ${diffDays} days ago`;
                                    }
                                })()}
                            </span>
                        </div>


                        {/* Number of Applicants */}
                        <div className="flex items-center space-x-2 text-gray-600">
                            <FontAwesomeIcon icon={faUsersLine} size='lg' className="text-green-500" />
                            <span className="text-lg font-medium">
                                {job.range.replace(/\D/g, '') - 2} Applicants
                            </span>
                        </div>
                    </div>


                    {/* Divider */}
                    <hr className="my-8 border-gray-300" />

                    {/* Content Section */}
                    <div className="max-w-[1300px] mx-auto p-6 ">
                        {/* Left Section: Job Description */}
                        <div className="col-span-2">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Job Description</h2>
                            <p className="text-lg text-gray-700 mb-6">{job.jobDescription}</p>

                            <div className="grid grid-cols-1 md:grid-cols-4 gap-x-8 mb-6">
                                <div>
                                    <p className="text-md text-gray-600 font-medium mb-2">Job Type</p>
                                    <p className="text-lg text-gray-800">{job.jobType}</p>
                                </div>
                                <div>
                                    <p className="text-md text-gray-600 font-medium mb-2">Location</p>
                                    <p className="text-lg text-gray-800">{job.location}</p>
                                </div>
                                <div>
                                    <p className="text-md text-gray-600 font-medium mb-2">Salary</p>
                                    <p className="text-lg text-gray-800">{job.salary}</p>
                                </div>
                                <div>
                                    <p className="text-md text-gray-600 font-medium mb-2">Experience</p>
                                    <p className="text-lg text-gray-800">{job.experience}</p> {/* Fixed experience reference */}
                                </div>
                            </div>


                            {/* Educational Qualification */}
                            <div className="mb-6">
                                <h3 className="text-xl font-semibold text-gray-800 mb-3">Educational Qualification</h3>
                                <p className="text-lg text-gray-700">{job.educationalQualification}</p>
                            </div>

                            {job.preferences && job.preferences.length > 0 && (
                                <div className="mb-6">
                                    <h3 className="text-xl font-semibold text-gray-800 mb-3">Preference</h3>
                                    <ul className="list-disc pl-6 space-y-2 text-lg text-gray-700">
                                        {job.preferences.map((preference, index) => (
                                            <li key={index}>{preference}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {/* Key Responsibilities */}
                            {job.keyResponsibilities && job.keyResponsibilities.length > 0 && (
                                <div className="mb-6">
                                    <h3 className="text-xl font-semibold text-gray-800 mb-3">Key Responsibilities</h3>
                                    <ul className="list-disc pl-6 space-y-2 text-lg text-gray-700">
                                        {job.keyResponsibilities.map((task, index) => (
                                            <li key={index}>{task}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {/* Skills */}
                            {job.skills && job.skills.length > 0 && (
                                <div className="mb-6">
                                    <h3 className="text-xl font-semibold text-gray-800 mb-3">Skills</h3>
                                    <ul className="list-disc pl-6 space-y-2 text-lg text-gray-700">
                                        {job.skills.map((skill, index) => (
                                            <li key={index}>{skill}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {/*Additional Questions */}
                            {job.questions && job.questions.length > 0 && (
                                <div className="mb-6">
                                    <h3 className="text-xl font-semibold text-gray-800 mb-3">Additional Questions</h3>
                                    <ul className="list-disc pl-6 space-y-2 text-lg text-gray-700">
                                        {job.questions.map((task, index) => (
                                            <li key={index}>{task}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                        </div>

                    </div>

                    {showModal && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                            <div className="bg-white p-6 rounded-lg shadow-xl w-110 max-w-md">
                                {/* Modal Header */}
                                <div className="flex items-center mb-4">
                                    <div className="text-lg font-semibold text-gray-900">
                                        <span className="text-xl font-bold text-red-500">Delete Confirmation</span>
                                    </div>
                                </div>

                                {/* Modal Message */}
                                <div className="text-center mb-4">
                                    <p className="text-gray-700 text-base">
                                        Are you sure you want to delete this job?
                                    </p>
                                    <p className="text-gray-700 text-base">
                                        This action can't be undone.
                                    </p>
                                </div>

                                {/* Info Message */}
                                <div className="flex items-start space-x-2 bg-yellow-50 border-l-4 border-yellow-500 p-4 mb-6">
                                    <p className="text-yellow-700 text-sm">
                                        Don't forget to delete the associated sheet and uploaded resumes from the drive if they are no longer needed.
                                        <span className="block mt-2">
                                            Use the following links:
                                            <a href={`https://docs.google.com/spreadsheets/d/${job.spreadsheetId}`}
                                                className="text-blue-500 underline flex items-center space-x-1 mt-2"
                                                target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faExternalLinkAlt} className="text-blue-500" />
                                                <span>Open Sheet</span>
                                            </a>
                                            <a href={`https://drive.google.com/drive/folders/${job.resumeFolderId}`}
                                                className="text-blue-500 underline flex items-center space-x-1 mt-2"
                                                target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faExternalLinkAlt} className="text-blue-500" />
                                                <span>Open Resume Folders</span>
                                            </a>
                                        </span>
                                    </p>
                                </div>

                                {/* Buttons */}
                                <div className="flex justify-between space-x-4">
                                    {/* Cancel Button */}
                                    <button
                                        className="bg-gray-500 text-white px-6 py-3 rounded-lg hover:bg-gray-600 transition duration-300 w-full sm:w-auto"
                                        onClick={handleCancel}
                                    >
                                        <span className="font-semibold">Cancel</span>
                                    </button>
                                    {/* Delete Button */}
                                    <button
                                        className="bg-red-500 text-white px-6 py-3 rounded-lg hover:bg-red-600 transition duration-300 w-full sm:w-auto"
                                        onClick={() => handleDeleteConfirm(job.jobTitle)}
                                    >
                                        <span className="font-semibold">Delete</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}




                </div>
            ) : (
                <p className="text-center text-xl text-gray-500 mt-8">Job not found.</p>
            )}

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default JobDetail;