import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";

// import { doc, getDoc } from "firebase/firestore";
// import { db } from "../../firebase";

import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import { Button } from "@material-tailwind/react";
import EnquireForm from "./EnquireForm";
import { FETCH_PRODUCT_DETAIL_API } from "../Api";

const ProductsDescription = () => {
  const { categoryId, subCategoryId, productId, categoryName, subCategoryName } = useParams();
  // create a state to store category and subcategory name
  // const [ categoryName, setCategoryName ] = useState('');
  // const [ subCategoryName, setSubCategoryName ] = useState('');
  const [isEnquiryFormOpen, setIsEnquiryFormOpen] = useState(false);
  const [prodData, setProdData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  var images;

  // const docRef = doc(
  //   db,
  //   "Categories",
  //   categoryId,
  //   "SubCategories",
  //   subCategoryId,
  //   subCategoryId + "_Products",
  //   productId
  // );
  // async function fetchProductDetailsFromFirestore() {
  //   const docSnap = await getDoc(docRef);
  //   if (docSnap.exists()) {
  //     // console.log("Document data:", docSnap.data());
  //     const tempProductData = docSnap.data();
  //     setProdData(tempProductData);
  //   } else {
  //     // doc.data() will be undefined in this case
  //     console.log("No such document!");
  //   }
  // }
  const fetchProductDetailsFromServer = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${FETCH_PRODUCT_DETAIL_API}${categoryId}/${subCategoryId}/${productId}`
      );
      const data = await response.json();
      setProdData(data);
    } catch (error) {
      setError("Error fetching product details! Given details invalid");
    } finally {
      setLoading(false);
    }
  }, [categoryId, subCategoryId, productId]); // Dependencies

  
  if (prodData) {
    images = prodData.img.map((img) => {
      return {
        src: img,
      };
    });
  }
  useEffect(() => {

    fetchProductDetailsFromServer();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [fetchProductDetailsFromServer]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      {prodData ? (
        <div>
          {isEnquiryFormOpen && (
            <EnquireForm
            productName = {prodData.name}
              onCancel={() => {
                setIsEnquiryFormOpen(!isEnquiryFormOpen);
              }}
            />
          )}
          <div className="mx-4 my-4 px-2">
            <h1 className=" text-center md:text-2xl text-lg font-extrabold font-sans md:py-6 py-4">
              {categoryName}
              <span className="text-green-600 font-bold">
                {" "}
                - {subCategoryName}
              </span>
            </h1>
          </div>
          <div className="mx-4 px-2">
            <h1 className=" text-center text-green-600 md:text-4xl text-2xl font-extrabold font-sans py-4 md:py-6">
              {prodData.name}
            </h1>
          </div>
          <div className="lg:mx-32 md:mx-24 md:px-6 md:py-10 px-6 py-4">
            <p className="text-gray-800 md:text-xl text-base text-justify leading-7 md:leading-10">
              {prodData.desc}
            </p>
            <div className=" py-6 my-6 flex md:flex-row flex-col justify-center">
              <div className="lg:w-1/2">
                <Carousel
                  images={images}
                  hasMediaButton={false}
                  hasIndexBoard={false}
                />
              </div>
              <br className="block md:hidden"/>
              <div className="lg:w-1/2 mx-1 my-2 md:m-1 py-4 md:py-3">
                <h1 className="text-center text-2xl font-bold text-gray-800">
                  Applications
                </h1>
                <div className="p-1 md:p-4 h-full m-2 flex flex-col justify-between">
                  <div className="py-4">
                    <ul className=" md:ml-4 md:pl-4 list-disc text-justify tracking-wide leading-relaxed text-gray-800">
                      {prodData.feat.map((feat, index) => {
                        return <li key={index}
                        className="py-2 font-kumbh text-base md:text-lg"
                        >{feat}</li>;
                      })}
                    </ul>
                  </div>
                  <div className="flex flex-row justify-center items-center">
                    <div className="pt-4 md:pt-0">
                      <Button
                        color="green"
                        variant="gradient"
                        size="sm"
                        className="mb-2"
                        onClick={() => {
                          setIsEnquiryFormOpen(!isEnquiryFormOpen);
                        }}
                      >
                        <span className="py-2 text-base">
                          Enquire now
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>Loading</div>
      )}
    </div>
  );
};

export default ProductsDescription;
