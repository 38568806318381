// DeleteConfirmation.js

import React, { useEffect } from "react";

const DeleteConfirmation = ({ onConfirm, onCancel }) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        onCancel();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [onCancel]);

  return (
    <div className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white p-8 rounded-xl shadow-md">
        <p className="text-lg font-semibold mb-4">
          Are you sure you want to delete this item?
        </p>
        <div className="flex justify-end">
          <button
            className="px-4 py-2 bg-red-500 text-white rounded mr-4"
            onClick={onConfirm}
          >
            Delete
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
