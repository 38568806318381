import React, { useEffect, useState } from "react";
import { Input, Option, Select } from "@material-tailwind/react";
import Navbar from "../AdminNav";
import { TrashIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import AddProduct from "./AddProduct";
import ErrorMsg from "./ErrorMsg";
import EditProduct from "./EditProduct";
import { MdClose } from "react-icons/md";
import {
  FETCH_CATEGORIES_API,
  FETCH_SUBCATEGORIES_API,
  FETCH_SUBCATEGORY_PRODUCTS_API,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import DeleteConfirmation from "../AddClientCompononents/DeleteConfirmation";
import { DELETE_PRODUCT_API } from "../../Api";
import axios from "axios";

const AddProducts = () => {
  //check if user is authenticated
  const navigate = useNavigate();
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const isTokenExpired = (token) => {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      const expiryTime = new Date(decodedToken.exp * 1000);
      console.log("Token expiry time:", expiryTime.toLocaleString());
      return decodedToken.exp < currentTime;
    };
    if (!authToken || isTokenExpired(authToken)) {
      console.log("AuthContext - user:", false);
      navigate("/signin");
    } else {
      console.log("AuthContext - user:", true);
    }
  }, [navigate]);
  const [categories, setCategories] = useState([]);


  const [search, setSearch] = useState("");

  const [subCategories, setSubCategories] = useState([]);


  const [categoryName, setcategoryName] = useState(null);
  const [subcategoryName, setSubcategoryName] = useState(null);

  const [products, setProducts] = useState([]);
  const [prodInfo, setProdInfo] = useState(null); //product info [name, desc, feat, img
  const [addProduct, setAddProduct] = useState(false); //toggle btn for add product
  const [editProduct, setEditProduct] = useState(false); //toggle btn for edit product

  
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  

  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleAddProductBtn = () => {
    setAddProduct(!addProduct);
  };

  const handleEditClick = (product) => {
    setProdInfo(product);
    setEditProduct(true);
  };

  const handleDeleteBtn = (product) => {
    setProdInfo(product);
    setDeleteConfirmation(!deleteConfirmation);
  };

  const deleteProduct = async ( categoryName, subcategoryName, prodInfo) => {

    try {
      console.log("Deleting product:", prodInfo);
      const response = await axios.delete(
        DELETE_PRODUCT_API +
        categoryName +
          "/" +
          subcategoryName +
          "/" +
          prodInfo
      );
      console.log("Product deleted successfully:", response.data);
    } catch (error) {
      console.error("Error deleting product:", error);
    } finally {
      setSelectedProduct(false);
      setDeleteConfirmation(false);
      setProdInfo(null);
      fetchProductsFromServer(subcategoryName);
    }
  };

  const handleCategoryClick = (category) => {
    setcategoryName(category);
    fetchSubCategoriesFromServer(category.id);
  };
  const handleSubCategoryClick = (subCategory) => {
    setSubcategoryName(subCategory);
    fetchProductsFromServer(subCategory.id);
  };

  async function fetchCategoriesFromServer() {
    try {
      const response = await fetch(FETCH_CATEGORIES_API);
      const categoriesData = await response.json();
      setCategories(categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }
  async function fetchProductsFromServer(subcategoryId) {
    if (!categoryName) {
      return;
    }
    try {
      const response = await fetch(
        FETCH_SUBCATEGORY_PRODUCTS_API + categoryName.id + "/" + subcategoryId
      );
      const productsData = await response.json();
      setProducts(productsData);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  }


  async function fetchSubCategoriesFromServer(categoryId) {
    try {
      const response = await fetch(FETCH_SUBCATEGORIES_API + categoryId);
      const subCategoriesData = await response.json();
      setSubCategories(subCategoriesData);
    } catch (error) {
      console.error("Error fetching sub-categories:", error);
    }
  }

  useEffect(() => {
    fetchCategoriesFromServer(); // Fetch categories when the component mounts
  }, []);

  return (
    <div>
      {/* <SignOutBtn /> */}
      <Navbar />
      {addProduct &&
        (categoryName ? (
          subcategoryName ? (
            (console.log(categoryName.id),
              console.log(subcategoryName.id),
              (
                <AddProduct
                  categoryId={categoryName.id}
                  subCategoryId={subcategoryName.id}
                  onClose={() => {
                    setAddProduct(false);
                    fetchProductsFromServer(subcategoryName.id);
                  }}
                />
              ))
          ) : (
            <ErrorMsg
              errMsg="Please select a Sub-category"
              onClose={() => {
                setAddProduct(false);
              }}
            />
          )
        ) : (
          <ErrorMsg
            errMsg="Please select a Category"
            onClose={() => {
              setAddProduct(false);
            }}
          />
        ))}
      {editProduct && (
  <EditProduct
    prodData={prodInfo}
    categoryId={categoryName.id}
    subCategoryId={subcategoryName.id}
    onClose={() => {
      setSelectedProduct(false);
      setEditProduct(false);
      setProdInfo(null);
      fetchProductsFromServer(subcategoryName.id);
    }}
  />
)}

          {deleteConfirmation && (
            <DeleteConfirmation
              onConfirm={() => deleteProduct(categoryName.id, subcategoryName.id, prodInfo.name)}
              onCancel={() => setDeleteConfirmation(false)}
            />
          )}

      <div className="px-4 md:px-6 mt-8 mb-4">
        {/* Title and Controls */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">

          {/* Left-aligned heading */}
          <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900">
            Manage Products
          </h2>

          {/* Right-aligned controls */}
          <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 w-full md:w-auto space-y-3 sm:space-y-0">

            {/* Category */}
            <div className="w-full sm:w-48">
              <Select label="Category" name="selectedCategory" className="w-full">
                {categories.map((category, index) => (
                  <Option
                    key={index}
                    value={category.name}
                    onClick={() => handleCategoryClick(category)}
                  >
                    {category.name}
                  </Option>
                ))}
              </Select>
            </div>

            {/* Sub-category */}
            <div className="w-full sm:w-48">
              <Select label="Sub-category" name="selectedSubCategory" className="w-full">
                {subCategories.map((subcat, index) => (
                  <Option
                    key={index}
                    value={subcat.name}
                    onClick={() => handleSubCategoryClick(subcat)}
                  >
                    {subcat.name}
                  </Option>
                ))}
              </Select>
            </div>

            {/* Add Product Button */}
            <div
              className="w-full sm:w-48 h-10 flex items-center justify-center bg-green-500 hover:bg-green-600 text-white rounded-lg cursor-pointer transition-all"
              onClick={handleAddProductBtn}
            >
              <p className="text-center text-sm lg:text-md">Add Product</p>
            </div>
          </div>
        </div>
      </div>

      {products.length > 0 ? (
        <>
          {/* Product Search */}
          <div className="p-2 mt-4 m-1 md:px-4 rounded-2xl hover:drop-shadow-lg h-11 hover:cursor-default">
            <Input
              label="Search for Products here..."
              type="text"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>

          {/* Product Grid */}
          <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4 mt-4">
              {products
                .filter(
                  (item) =>
                    search === "" || item.name.toLowerCase().includes(search.toLowerCase())
                )
                .map((item, index) => (

                  <div
                    key={index}
                    onClick={() => setSelectedProduct(item)}
                    className="relative flex flex-col bg-white rounded-xl shadow-md p-2 border border-gray-300 cursor-pointer transition-all transform hover:scale-105 hover:shadow-lg group"
                  >
                    {/* Row 1: Name & Delete Icon */}
                    <div className="flex justify-between items-center px-2 mt-3">
                      {/* Name */}
                      <p className="text-lg font-bold text-[18px] font-poppins">
                        {item.name}
                      </p>


                    </div>

                    {/* Row 2: Description (Max 3 lines) */}
                    <p className="text-sm text-gray-600 line-clamp-3 overflow-hidden px-2 mt-2">
                      {item.desc}
                    </p>

                    {/* Row 3: Background Image with Overlay */}
                    <div
                      className="relative w-full h-[200px] mt-4 rounded-lg bg-cover bg-center overflow-hidden"
                      style={{ backgroundImage: `url(${item.img[0]})` }}
                    >
                      {/* Black Overlay */}
                      <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg"></div>
                    </div>
                  </div>


                ))}
            </div>



            {/* Modal */}
            {selectedProduct && (
              <div className="fixed inset-0 z-50 flex">
                {/* Black Overlay on the left side */}
                <div
                  className="hidden sm:block flex-grow bg-black bg-opacity-50 transition-opacity duration-500 ease-in-out"
                  onClick={() => setSelectedProduct(false)}
                ></div>

                {/* Side Panel */}
                <div
                  className="relative h-full w-full sm:w-[650px] bg-white shadow-lg transform transition-transform duration-500 ease-in-out flex flex-col"
                >
                  {/* Close Button */}
                  <button
                    className="absolute top-4 right-4 text-gray-600 hover:text-red-500"
                    onClick={() => setSelectedProduct(false)}
                  >
                    <MdClose className="w-6 h-6" />
                  </button>

                  {/* Content Area - Scrollable */}
                  <div className="flex-1 overflow-y-auto p-4" style={{ maxHeight: "calc(100vh - 60px)" }}>
                    {/* Title */}
                    <h2 className="text-lg font-bold text-gray-900">{selectedProduct.name}</h2>
                    <hr className="my-2 border-gray-300" />

                    {/* Image */}
                    <div className="relative w-full h-[30%] rounded-lg overflow-hidden">
                      <img
                        src={selectedProduct.img[0]}
                        alt="Product"
                        className="w-full h-full object-cover rounded-lg"
                      />
                      {/* Black Overlay */}
                      <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg"></div>
                    </div>

                    {/* Description */}
                    <div className="mt-4 px-2">
                      <p className="text-gray-900 text-l font-medium mt-1">{selectedProduct.desc}</p>
                    </div>

                    {/* Features */}
                    <div className="mt-4 px-2">
                      <div className="space-y-3 mt-2">
                        {selectedProduct.feat.map((feat, index) => (
                          <div
                            key={index}
                            className="relative bg-white shadow-md rounded-2xl p-4"
                          >
                            {/* Green Line on the Left */}
                            <span className="absolute top-0 left-0 h-full w-1 bg-green-500 rounded-l-2xl"></span>

                            <p className="text-gray-700 text-sm">{feat}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Spacer to ensure all content is visible */}
                    <div className="h-5"></div>
                  </div>

                  {/* Fixed Bottom Buttons */}
                  <div className="absolute bottom-0 left-0 w-full bg-white shadow-md flex px-2 py-4 gap-3 border-t border-gray-200">
                    {/* Edit Button */}
                    <button className="flex-1 flex items-center justify-center bg-gradient-to-r from-green-500 to-green-600 text-white py-3 text-sm font-medium rounded-lg shadow-md transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg active:scale-90 focus:outline-none relative overflow-hidden group" onClick={() => handleEditClick(selectedProduct)}>
                      <span className="absolute inset-0 bg-white opacity-0 group-hover:opacity-10 transition-opacity duration-300"></span>
                      <PencilSquareIcon className="w-5 h-5 mr-2" /> Edit
                    </button>

                    {/* Delete Button */}
                    <button className="flex-1 flex items-center justify-center bg-gradient-to-r from-red-500 to-red-600 text-white py-3 text-sm font-medium rounded-lg shadow-md transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg active:scale-90 focus:outline-none relative overflow-hidden group " onClick={() => handleDeleteBtn(selectedProduct)}>
                      <span className="absolute inset-0 bg-white opacity-0 group-hover:opacity-10 transition-opacity duration-300"></span>
                      <TrashIcon className="w-5 h-5 mr-2" /> Delete
                    </button>
                  </div>

                </div>
              </div>
            )}


          </div>
        </>
      ) : (
        // Centered message when no products are available
        <div className="flex-grow flex items-center justify-center text-center p-6 mt-60">
          <p className="text-xl sm:text-2xl md:text-3xl font-semibold text-gray-600">
            Manage your site products by updating or adding a new product.
          </p>
        </div>

      )}
    </div>
  );
};

export default AddProducts;
