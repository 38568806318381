import { useState } from "react";
import { Button } from "@material-tailwind/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiMenu, FiX, FiHome, FiBox, FiUsers, FiLayers, FiStar, FiBriefcase } from "react-icons/fi";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const navItems = [
    { path: "/admin", label: "Home", icon: <FiHome /> },
    { path: "/addproduct", label: "Products", icon: <FiBox /> },
    { path: "/addclient", label: "Clients", icon: <FiUsers /> },
    { path: "/addcategory", label: "Categories", icon: <FiLayers /> },
    { path: "/addtestimonial", label: "Testimonials", icon: <FiStar /> },
    // { path: "/addmanual", label: "Product Manual", icon: <FiBook /> },
    { path: "/admincareer", label: "Careers", icon: <FiBriefcase /> },
  ];

  async function handleSignOut() {
    try {

      localStorage.removeItem("user");
      localStorage.removeItem("authToken"); // Remove the token from localStorage
      localStorage.setItem("isLoggedIn", false);
      navigate("/signin");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <nav className="bg-white text-gray-900 p-4 flex items-center justify-between shadow-md w-full">
      {/* Logo */}
      <div className="text-xl font-bold">SUN TEKNOLOZY</div>
      
      {/* Menu Button for Mobile */}
      <button className="md:hidden" onClick={() => setIsOpen(!isOpen)}>
        <FiMenu size={24} />
      </button>
      
      {/* Navigation Links (Desktop) */}
      <ul className="hidden md:flex space-x-6 text-sm font-medium">
        {navItems.map((item) => (
          <Link key={item.path} to={item.path}>
            <li className={`flex items-center space-x-2 px-3 py-2 rounded-lg cursor-pointer transition-all ${location.pathname === item.path ? "bg-green-500 text-white" : "hover:bg-green-100 hover:text-green-600"}`}>
              {item.icon}
              <span>{item.label}</span>
            </li>
          </Link>
        ))}
      </ul>
      
      {/* Sign Out Button (Desktop) */}
      <Button className="hidden md:block bg-red-500 hover:bg-red-600 px-4 py-2 text-white rounded-lg" onClick={() => {
            handleSignOut();
          }}>Sign Out</Button>
      
      {/* Mobile Drawer */}
      {isOpen && (
  <div className="fixed top-0 left-0 w-3/4 h-full bg-gray-100 shadow-xl p-6 transition-transform transform md:hidden z-50">
    <div className="flex justify-between items-center mb-6">
      <h2 className="text-lg font-bold">Menu</h2>
      <button onClick={() => setIsOpen(false)} className="text-gray-600">
        <FiX size={24} />
      </button>
    </div>
    <ul className="space-y-4 text-sm font-medium">
      {navItems.map((item) => (
        <Link key={item.path} to={item.path} onClick={() => setIsOpen(false)}>
          <li className={`flex items-center space-x-2 px-3 py-2 rounded-lg cursor-pointer transition-all ${location.pathname === item.path ? "bg-green-500 text-white" : "hover:bg-green-100 hover:text-green-600"}`}>
            {item.icon}
            <span>{item.label}</span>
          </li>
        </Link>
      ))}
    </ul>
    <Button className="mt-6 w-full bg-red-500 hover:bg-red-600 px-4 py-2 text-white rounded-lg" onClick={() => handleSignOut()}>
      Sign Out
    </Button>
  </div>
)}
    </nav>
  );
}