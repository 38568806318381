import React, { useState, useEffect , useCallback} from "react";
import classNames from "classnames";
// firebase imports
// import { collection, getDocs, doc, getDoc } from "firebase/firestore";
// import { db } from "../../../firebase";
import { useParams } from "react-router-dom";
import ProductItem from "./ProductItem";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./Carousel.css";
import Slider from "react-slick";
import { CarouselSettings } from "./CarouselSettings";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Input } from "@material-tailwind/react";
import {
  ALL_PRODUCTS_API,
  FETCH_CATEGORY_DETAIL_API,
  FETCH_PRODUCTS_BY_INDEX_API,
} from "../../Api";
import { CiLocationArrow1 } from "react-icons/ci";

const ProductList_by_Category = () => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0); // for changing the color of the selected subCat

  const [categories, setCategories] = useState([]); // for storing sub category documents
  // const tempSubCategoryData = []; // temporary array to store subCat documents
  // const tempProdRef = []; // temporary array to store subCat product references
  // eslint-disable-next-line
  const [prodRef, setProdRef] = useState([]); // for storing subCat product references
  const [search, setSearch] = useState(""); // for storing search input
  // const tempAllProdData = []; // temporary array to store all products
  const [subCategoryProd, setSubCategoryProd] = useState(null); // [prod1,prod2,prod3]
  const [currentCatergoryInfo, setCurrentCategoryInfo] = useState(); // for storing current category details
  const [allProducts, setAllProducts] = useState([]); // for storing all products
  const [isSearchOpen, setIsSearchOpen] = useState("hidden");
  // eslint-disable-next-line
  var notFound = false;

  // let alreadyFetched = false;

  const { categoryId } = useParams();
  // handle search input
  const handleSearchInput = (e) => {
    setSearch(e.target.value);
    if (e.target.value !== "") {
      setIsSearchOpen("absolute");
    } else {
      setIsSearchOpen("hidden");
    }
    // console.log("Search input: ", search);
  };

  // fetch the selected subCat products and change the color of the selected subCat
  const handleClick = (index) => {
    // console.log("Sub category name: ", subCategoryName);
    fetchProductsFromServer(index);
    setSelectedItemIndex(index);
  };

  //classNames to conditionally apply Tailwind classes
  const getItemClasses = (index) =>
    classNames("cursor-pointer", {
      "bg-green-200": selectedItemIndex === index,
      "bg-gray-100": selectedItemIndex !== null && selectedItemIndex !== index,
      "font-bold": selectedItemIndex === index,
      "font-normal": selectedItemIndex !== null && selectedItemIndex !== index,
    });

  // fetch the category details and sub-categories
  // async function fetchCategoryDetailsFromFirestore() {
  //   if (alreadyFetched) {
  //     return;
  //   }
  //   //get current category details
  //   const currentCatergoryDoc = doc(db, "Categories", categoryId);
  //   const currentCategoryDocSnap = await getDoc(currentCatergoryDoc);

  //   if (currentCategoryDocSnap.exists()) {
  //     // console.log("Document data:", currentCategoryDocSnap.data());
  //     const tempCurrrentCategoryData = currentCategoryDocSnap.data();
  //     setCurrentCategoryInfo(tempCurrrentCategoryData);
  //     // console.log(
  //     //   tempCurrrentCategoryData.name + " " + tempCurrrentCategoryData.desc
  //     // );
  //   }

  //   //get sub categories
  //   const subCategoriesCollection = collection(
  //     db,
  //     "Categories",
  //     categoryId,
  //     "SubCategories"
  //   );

  //   try {
  //     const querySnapshot = await getDocs(subCategoriesCollection); // Fetch all documents in the 'products' collection

  //     querySnapshot.forEach((item) => {
  //       const SubCategoryData = item.data();
  //       const subCategoryProdRef = collection(
  //         db,
  //         "Categories",
  //         categoryId,
  //         "SubCategories",
  //         SubCategoryData.id,
  //         SubCategoryData.id + "_Products"
  //       );
  //       tempProdRef.push(subCategoryProdRef);
  //       // console.log(SubCategoryData.name);
  //       tempSubCategoryData.push(SubCategoryData);
  //     });

  //     // Set the categories data and subCat product references in state
  //     setCategories(tempSubCategoryData);
  //     setProdRef(tempProdRef);
  //   } catch (error) {
  //     console.error("Error fetching sub category list:", error);
  //   }
  //   fetchProductsFromFirestore(0, tempProdRef);
  // }

  //   // fetch the products of the selected sub-category
  //   async function fetchProductsFromFirestore(index, refState) {
  //     try {
  //       // console.log("Index: ", index + " " + refState[index]);
  //       const prodQuerySnapshot = await getDocs(refState[index]); // Fetch all documents in the 'products' collection
  //       prodQuerySnapshot.forEach((item) => {
  //         const tempProdData = item.data();
  //         console.log("Product Name: ", tempProdData.name);
  //         tempAllProdData.push(tempProdData);
  //       });
  //     } catch (error) {
  //       console.log("Error fetching products list:", error);
  //     }
  //     setSubCategoryProd(tempAllProdData);
  //   }

  async function fetchAllProductsFromServer() {
    try {
      const response = await fetch(ALL_PRODUCTS_API);
      const productsData = await response.json();
      setAllProducts(productsData);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  }
  const fetchCategoryDetailsFromServer = useCallback(async () => {
    try {
      const response = await fetch(FETCH_CATEGORY_DETAIL_API + `${categoryId}`);
      const { categoryDetails, subCategoryData, prodRef } = await response.json();
  
      console.log("Sub category name: ", subCategoryData[0]?.name);
      setCurrentCategoryInfo(categoryDetails);
      setCategories(subCategoryData);
      setProdRef(prodRef);
  
      // Fetch products for the first sub-category
      fetchProductsFromServer(0);
    } catch (error) {
      console.error("Error fetching category details:", error);
    }
  }, [categoryId]);

  async function fetchProductsFromServer(index) {
    try {
      const response = await fetch(FETCH_PRODUCTS_BY_INDEX_API + `${index}`);
      const productsData = await response.json();
      setSubCategoryProd(productsData);
     
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  }

  useEffect(() => {
    fetchCategoryDetailsFromServer();
    fetchAllProductsFromServer();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [fetchCategoryDetailsFromServer]);

  return (
    <div>
      {/* <div className="mx-4 px-2">
        <h1 className=" text-center md:text-5xl text-3xl font-extrabold font-sans py-10">
          Our<span className="text-green-600 font-bold"> Products</span>
        </h1>
      </div> */}

      {/* Search Bar */}
      <div className="mt-3 mb-3 flex flex-row justify-center align-middle items-center">
        <div className="md:w-2/3">
          <Input
            // style={{ width: "50%" }}
            variant="standard"
            name="Search"
            placeholder="Search products across all categories"
            onChange={handleSearchInput}
            type="text"
            className="p-2"
            icon={<AiOutlineFileSearch />}
          />
        </div>
      </div>

      {/* Suggestion box based on Input */}
      <div
        className={`${isSearchOpen} z-50 left-2/4 transform -translate-x-1/2 
        w-3/4 lg:w-fit h-fit flex flex-col justify-center items-center
       mx-1 my-4 p-1 bg-gray-100 rounded-xl`}
      >
        <ul className="text-sm">
          {allProducts && (
            <div className="w-full">
              {allProducts
                .filter((item) => {
                  if (search === "") {
                    notFound = false;
                    return false;
                  } else if (
                    search.length >= 4 &&
                    (item.name.toLowerCase().includes(search.toLowerCase()) ||
                      item.feat.toLowerCase().includes(search.toLowerCase()) ||
                      item.categoryId.toLowerCase().includes(search.toLowerCase()) ||
                      item.subCategoryId.toLowerCase().includes(search.toLowerCase())
                      ) 
                  ) {
                    notFound = false;
                    return true;
                  }
                  notFound = true;
                  return false;
                })
                .map((product, index) => (
                  <div key={index} 
                  className="flex justify-between items-center align-middle"
                  >
                    <li
                    
                    className=" my-1 px-2 py-1 rounded-xl
                    text-xs md:text-base font-kumbh font-normal w-fit
                    hover:bg-gray-300"
                  >
                    <a
                      href={
                        product.categoryId +
                        "/" +
                        product.subCategoryId +
                        "/" +
                        product.name +
                        "/" +
                        currentCatergoryInfo.name +
                        "/" +
                        categories[selectedItemIndex].name
                      }
                    >
                      {product.name} 
                    </a>
                    
                  </li>
                  <CiLocationArrow1 className="mx-1 w-3 h-3"/>
                  </div>
                ))}
            </div>
          )}
        </ul>
      </div>

      {/* Display the name of the category/ business vertical */}
      {currentCatergoryInfo && (
        <div>
          <div className="mt-6 md:mt-11 p-2">
            <h3 className="text-center text-2xl md:text-4xl font-extrabold font-kumbh">
              {" "}
              {currentCatergoryInfo.name}{" "}
              <span className="text-green-600 font-bold"> Equipments</span>
            </h3>
          </div>

          <div className="lg:mx-30 md:mx-24 md:px-6 md:py-7 mt-4 md:mt-4 px-8 py-4">
            <p className="text-gray-800 md:text-xl text-md text-justify leading-9 md:leading-10">
              {currentCatergoryInfo.desc}
            </p>
          </div>
        </div>
      )}

      {/* Sub-category chips- display them fully horizontally and contain overflow */}
      <div className="lg:mx-26 md:mx-20 md:px-6 md:pb-7 pb-8 ">
        {/* <div
          className="flex flex-col md:flex-row justify-evenly items-center"
          // className="grid grid-cols-1 md:grid-cols-4 gap-5"
        >
          {categories &&
            categories.length > 0 &&
            categories.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`p-2 mx-1 my-2 rounded-2xl bg-gray-100 ${getItemClasses(
                    index
                  )} hover:bg-green-100 hover:drop-shadow-lg w-fit h-9 hover:cursor-default transform transition-all hover:scale-110`}
                  onClick={() => {
                    handleClick(index);
                  }}
                >
                  <p className="text-center text-md">{item.name}</p>
                </div>
              );
            })}
        </div> */}


  <div className="flex items-center overflow-x-scroll md:flex-grow">
    {categories &&
      categories.length > 0 &&
      categories.map((item, index) => {
        return (
          <div
            key={index}
            className={`p-2 mx-4 my-2 rounded-2xl bg-gray-100 ${getItemClasses(
              index
            )} hover:bg-green-100 min-w-fit hover:drop-shadow-lg h-9 
            hover:cursor-default transform transition-all hover:scale-110`}
            onClick={() => {
              handleClick(index, item.name);
            }}
          >
            <p className="text-center min-w-fit text-sm md:text-base">{item.name}</p>
          </div>
        );
      })}
  </div>



        {/* {subCategoryProd &&
          console.log(
            "Sub category products: ",
            subCategoryProd,
            alreadyFetched
          )} */}

        {/* Display products by sub-category wise */}
        {subCategoryProd && subCategoryProd.length > 0 ? (
          <div>
            <div className="block md:hidden mt-2 pt-4">
              <Slider {...CarouselSettings}>
                {subCategoryProd.map((product, index) => (
                  <div key={index}>
                    <ProductItem
                      imageUrl={product.img[0]} //"https://www.crescentindustrial.co.uk/wp-content/uploads/2021/07/HV1200-UV-900x600-1.png"
                      title={product.name}
                      desc={product.desc}
                      feat={product.feat}
                      productUrl={
                        product.categoryId +
                        "/" +
                        product.subCategoryId +
                        "/" +
                        product.id +
                        "/" +
                        currentCatergoryInfo.name +
                        "/" +
                        categories[selectedItemIndex].name
                      }
                    />
                    {/* Add other product details and styling as needed */}
                  </div>
                ))}
              </Slider>
            </div>
            <div className="hidden md:grid grid-cols-4 pt-6">
              {subCategoryProd
                // .filter((item) => {
                //   if (search == "") {
                //     notFound = false;
                //     return true;
                //   } else if (
                //     item.name.toLowerCase().includes(search.toLowerCase())
                //   ) {
                //     notFound = false;
                //     return true;
                //   }
                //   notFound = true;
                //   return false;
                // })
                .map((product, index) => (
                  <div key={index}>
                    <ProductItem
                      imageUrl={product.img[0]} //"https://www.crescentindustrial.co.uk/wp-content/uploads/2021/07/HV1200-UV-900x600-1.png"
                      title={product.name}
                      desc={product.desc}
                      feat={product.feat}
                      productUrl={
                        product.categoryId +
                        "/" +
                        product.subCategoryId +
                        "/" +
                        product.id +
                        "/" +
                        currentCatergoryInfo.name +
                        "/" +
                        // subCategoryName
                        categories[selectedItemIndex].name
                      }
                    />
                  </div>
                ))}
              {subCategoryProd.length < 1 && (
                <div className="col-span-4">
                  <p className="text-center my-5 text-lg text-gray-800 font-normal">
                    No products found
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : subCategoryProd && subCategoryProd.length < 1 ? (
          <div className="mx-4 px-2">
            <p className="text-center my-5 text-lg text-gray-800 font-normal">
              No products found
            </p>
          </div>
        ) : (
          <div className="mx-4 px-2">
            <p className="text-center my-5 text-lg text-gray-800 font-normal">
              Wait till App loads
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductList_by_Category;
