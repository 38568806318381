import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import InputField from "./InputField";
import aquatrolls from '../../assets/aquatrools-600.mp4';
import FormAction from "./FormAction";
import { loginFields } from "./LoginFields";
import { LOGIN_API, RAISE_ALERT_API } from "../Api";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const SignIn = () => {
  const navigate = useNavigate();
  const fields = loginFields;
  let fieldsState = {};

  fields.forEach((field) => (fieldsState[field.id] = ""));
  const [loginState, setLoginState] = useState(fieldsState);

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  const authenticateUser = async (event) => {
    event.preventDefault();
    const { email, password, name, reason } = event.target;
    const emailValue = email.value;
    const passwordValue = password.value;
    const nameValue = name.value;
    const reasonValue = reason.value;

    const alertFormData = new FormData();
    alertFormData.append("userName", nameValue);
    alertFormData.append("reason", reasonValue);

    try {
      await axios.post(RAISE_ALERT_API, alertFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("Alert sent successfully");
    } catch (error) {
      console.log("Error: ", error);
    }

    try {
      const response = await axios.post(
        LOGIN_API,
        { emailValue, passwordValue },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem("authToken", token);
        localStorage.setItem("isLoggedIn", true);
        console.log("Login successful");
        alert("Login successful");
        navigate("/admin");
      } else {
        console.error("Login failed");
        alert(response.status);
      }
    } catch (error) {
      console.error("Login Failed", error);
      alert(error);
    }
  };

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const isTokenExpired = (token) => {
      const decodedToken = jwtDecode(token);
      return decodedToken.exp < Date.now() / 1000;
    };
    if (authToken && !isTokenExpired(authToken)) {
      navigate("/admin");
    }
  }, [navigate]);

  return (
    <div className="flex h-screen bg-gray-100 p-2 bg-white">
      {/* Left Section - Carousel */}
      <div className="w-full max-w-3xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden hidden md:block">
  {/* Top Section - Large Background Image with Text Overlay */}
  <div className="relative w-full h-80 rounded-lg overflow-hidden">
    {/* Background Image */}
    <div className="w-full h-full rounded-lg relative overflow-hidden">
  {/* Background Video */}
  <video
    className="absolute top-0 left-0 w-full h-full object-cover"
    autoPlay
    loop
    muted
    playsInline
  >
    <source src={aquatrolls} type="video/mp4" />
    Your browser does not support the video tag.
  </video>


</div>


    {/* Dark Overlay */}
    <div className="absolute inset-0 bg-black bg-opacity-50"></div>

    {/* Text Overlay */}
    <div className="absolute bottom-5 left-5 text-white p-4">
      <h2 className="text-2xl font-bold ">SUN TEKNOLOZY</h2>
      <p className="text-md ">Measure, integrate, and analyze the real-time environment with us.</p>
    </div>
  </div>

  {/* Bottom Section - Two Columns */}
  <div className="grid grid-cols-2 gap-2 pt-2">
    {/* Column 1 */}
    <div className="relative w-full h-[400px]">
      <div
        className="w-full h-full bg-cover bg-center rounded-lg"
        style={{
          backgroundImage: `url(https://i.pinimg.com/474x/ab/ca/53/abca5353a9820268cb6fbb3cb005cb10.jpg)`,
        }}
      ></div>
      <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg"></div>
      <div className="absolute bottom-4 left-4 text-white">
        <h2 className="text-lg font-bold drop-shadow-md">Explore the Products</h2>
        <p className="text-sm drop-shadow-md">Discover new products for environmental monitoring and empower our clients to make the most of them.</p>
      </div>
    </div>

    {/* Column 2 */}
    <div className="relative w-full h-[400px]">
      <div
        className="w-full h-full bg-cover bg-center rounded-lg"
        style={{
          backgroundImage: "url('https://i.pinimg.com/474x/f9/2c/2e/f92c2e8af3212a52df4bce01021cf7d0.jpg')",
        }}
      ></div>
      <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg"></div>
      <div className="absolute bottom-4 left-4 text-white">
        <h2 className="text-lg font-bold drop-shadow-md">Empower Future Careers</h2>
        <p className="text-sm drop-shadow-md">Expand opportunities by creating new career <br />paths for young graduates and aspiring professionals, and let them join our mission.</p>
      </div>
    </div>
  </div>
</div>





      {/* Right Section - Form */}
      <div className="w-full md:w-1/2 flex flex-col bg-white pt-2 p-8 shadow-lg relative">

  {/* Centered Content */}
  <div className="flex flex-col justify-center items-center h-full mt-12 text-center">
    {/* Main Heading */}
    <h2 className="text-3xl font-bold text-gray-900">Admin Portal</h2>

    {/* Subtitle */}
    <p className="mt-2 text-gray-600">Enter your details to access the admin panel and customize your site effortlessly.</p>

    {/* Form */}
    <form onSubmit={authenticateUser} className="mt-2 w-full max-w-full">
      <div className="space-y-4">
        {fields.map((field) => (
          <InputField
            key={field.id}
            handleChange={handleChange}
            value={loginState[field.id]}
            labelText={field.labelText}
            labelFor={field.labelFor}
            id={field.id}
            name={field.name}
            type={field.type}
            isRequired={field.isRequired}
            placeholder={field.placeholder}
            label={field.label}
          />
        ))}
      </div>

      {/* Submit Button */}
      <div className="mt-6">
        <FormAction text="Login" />
      </div>
    </form>
  </div>
</div>



    </div>
  );
};

export default SignIn;
