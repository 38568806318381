import React, { useEffect, useState } from "react";
import { IoIosAddCircleOutline } from "react-icons/io";
import { Input } from "@material-tailwind/react";
import { DELETE_TESTIMONIAL_API, FETCH_TESTIMONIAL_API } from "../../Api";
import axios from "axios";
import DeleteConfirmation from "../AddClientCompononents/DeleteConfirmation";
import ErrorMsg from "../AddProductComponents/ErrorMsg";
import AddTestimonial from "./AddTestimonial";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Navbar from "../AdminNav";
import { TrashIcon } from "@heroicons/react/24/outline";

const AddTestimonials = () => {
  const [search, setSearch] = useState(""); //search typing state
  const [testimonialData, setTestimonialData] = useState(null); //testimonial data state
  const [selectedTestimonial, setSelectedTestimonial] = useState(false); //selected testimonial state
  const [selectedTestimonialName, setSelectedTestimonialName] = useState(false); //selected testimonial id state
  const [selectedTestimonialIndex, setSelectedTestimonialIndex] =
    useState(false); //selected testimonial index state
  const [errorMsgPopup, setErrorMsgPopup] = useState(false); //error message state
  const [errorMsg, setErrorMsg] = useState(""); //error message state
  const [addTestimonial, setAddTestimonial] = useState(false); //add testimonial state

  //  check if user is authenticated
  const navigate = useNavigate();
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const isTokenExpired = (token) => {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      const expiryTime = new Date(decodedToken.exp * 1000);
      console.log("Token expiry time:", expiryTime.toLocaleString());
      return decodedToken.exp < currentTime;
    };
    if (!authToken || isTokenExpired(authToken)) {
      console.log("AuthContext - user:", false);
      navigate("/signin");
    } else {
      console.log("AuthContext - user:", true);
    }
  }, [navigate]);


  const handleaddTestimonialBtn = () => {
    setAddTestimonial(!addTestimonial);
  };
  // const handleImgClickBtn = (manual) => {
  //   console.log("Clicked : ", manual.name);
  //   setSearch(manual.name);
  //   setTestimonialName(manual.name);
  //   setSelectedTestimonialIndex(!selectedTestimonialIndex);
  //   setSelectedTestimonialName(manual.id);
  //   if (selectedTestimonialIndex) {
  //     setSearch("");
  //   }

  //   if (manual.id === selectedTestimonialName) {
  //     setSelectedTestimonialName(null);
  //     setSelectedTestimonialIndex(false);
  //     setSelectedTestimonial(false);
  //     setTestimonialName("");
  //   }
  // };

  const deleteTestimonial = async () => {
   console.log("Deleting testimonial with ID:", selectedTestimonialName.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '_'));
    try {
      const response = await axios.delete(
        DELETE_TESTIMONIAL_API + selectedTestimonialName.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '_')
      );
      if (response.status === 200) {
        console.log("Manual deleted successfully");
        setSelectedTestimonialName(null);
        fetchTestimonials();
      } else if ((response.status = 202)) {
        console.log("Manual does not exist");
        setErrorMsg("Manual does not exist");
        setErrorMsgPopup(true);
      }
      setSelectedTestimonial(!selectedTestimonial);
    } catch (error) {
      console.error("Error deleting file:", error);
      setErrorMsg("Error deleting file");
      setErrorMsgPopup(true);
    }
  };

  const fetchTestimonials = async () => {
    try {
      const response = await fetch(FETCH_TESTIMONIAL_API);
      const data = await response.json();
      if (response.status === 404) {
        setErrorMsg(response.statusText);
        setErrorMsgPopup(true);
        return;
      }
      setTestimonialData(data);
    } catch (error) {
      console.error("Error fetching testimonials:", error);
      // setErrorMsg("Error fetching testimonials");
      // setErrorMsgPopup(true);
    }
  };

  const handleDeleteBtn = (item) => {
    setSelectedTestimonialName(item);
    console.log(selectedTestimonialName);
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setSelectedTestimonialIndex(false);
        setSearch("");
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedTestimonialIndex]);

  // console.log("Testimonial data: ", testimonialData);
  return (
    <div>
      {/* <SignOutBtn /> */}
      <Navbar/>
      {errorMsgPopup && (
        <ErrorMsg
          message={errorMsg}
          onClose={() => {
            setErrorMsgPopup(false);
          }}
        />
      )}
      {addTestimonial && (
        <AddTestimonial
          onClose={() => {
            setAddTestimonial(!addTestimonial);
            fetchTestimonials();
          }}
        />
      )}
      {selectedTestimonialName && (
        <DeleteConfirmation
          onConfirm={() => {
            deleteTestimonial();
            setSelectedTestimonialIndex(false);
            setSelectedTestimonial(false);
            setSearch("");
          }}
          onCancel={() => {
            setSelectedTestimonialIndex(false);
            setSelectedTestimonialName(null);
            setSelectedTestimonial(false);
            setSearch("");
          }}
        />
      )}

<div className="px-4 md:px-6 mt-8 mb-4">
  <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
    {/* Title */}
    <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900">
      Manage Testimonials
    </h2>

    {/* Controls (Search & Add Button) */}
    <div className="flex flex-col sm:flex-row sm:items-center gap-4 w-full md:w-auto">
      {/* Add Testimonial Button */}
      <button
  className="w-full sm:w-48 h-10 flex items-center justify-center bg-green-500 hover:bg-green-600 text-white rounded-lg transition-all px-4 whitespace-nowrap"
  onClick={handleaddTestimonialBtn}
>
  <IoIosAddCircleOutline className="w-5 h-5 mr-1 shrink-0" />
  <span className="text-sm lg:text-base font-medium">Add Testimonial</span>
</button>


      {/* Search Input - Responsive Width */}
      <div className="w-full sm:max-w-xs md:max-w-sm lg:max-w-md">
        <Input
          label="Search for Testimonial..."
          type="text"
          className="w-full"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  </div>
</div>


      {/* <div className=" Buttons lg:mx-12 md:mx-6 md:px-6 md:py-10  flex flex-row justify-around align-middle">
        <div
          className=" m-1 px-4 w-1/6 flex flex-col justify-center items-middle rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg  h-auto hover:cursor-default transform transition-all hover:scale-110"
          onClick={() => {
            handleaddTestimonialBtn();
          }}
        >
          <p className="hidden md:block text-center text-md">Add Testimonial</p>
          <IoIosAddCircleOutline className="md:hidden w-9" />
        </div>

        <div
          onClick={() => {
            handleDeleteBtn();
          }}
          className={` m-1 px-4 rounded-2xl flex flex-col 
        justify-center items-middle bg-gray-100 ${getItemClassesDelete()}
      hover:bg-green-100 hover:drop-shadow-lg w-1/6 h-auto
        hover:cursor-default transform transition-all hover:scale-110`}
          //className=" m-1 px-4 flex flex-col justify-center items-middle rounded-2xl bg-gray-100 hover:bg-green-100 hover:drop-shadow-lg w-1/6 h-auto hover:cursor-default transform transition-all hover:scale-110"
          //   onClick={() => {
          //     handleClick(index);
          //   }}
        >
          <p className="hidden md:block text-center text-md">
            Delete Testimonial
          </p>
          <MdDeleteOutline className="md:hidden w-9 " />
        </div>

        <div className=" m-1 md:px-4 rounded-2xl hover:drop-shadow-lg w-min md:w-3/6 h-11 hover:cursor-default">
          <Input
            label="Search"
            type="text"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <Typography
            variant="small"
            color="gray"
            className="mt-2 flex items-center gap-1 font-normal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="-mt-px h-4 w-4"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
              />
            </svg>
            Search for Testimonial
          </Typography>
        </div>
      </div> */}

<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-4 md:p-6">
  {testimonialData && testimonialData.length > 0 ? (
    testimonialData
      .filter((item) => {
        if (search === "") return item;
        return item.name.toLowerCase().includes(search.toLowerCase());
      })
      .map((item, index) => (
        <div
          key={index}
          className="relative bg-white shadow-lg rounded-xl p-6 border border-gray-200 group transition-all duration-300 overflow-hidden"
        >
          {/* Hover Overlay with Centered Delete Button */}
          <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex justify-center items-center">
            <button
              className="flex justify-center items-center w-12 h-12 bg-red-600 text-white rounded-full shadow-md hover:bg-red-700 transition-transform transform hover:scale-110"
              onClick={() => handleDeleteBtn(item.name)}
            >
              <TrashIcon className="w-6 h-6" />
            </button>
          </div>

          {/* Quote Icon */}
          <div className="text-gray-400 text-4xl mb-3">❝</div>

          {/* Testimonial Text */}
          <p className="text-gray-700 text-sm leading-relaxed mb-4">
            {item.desc}
          </p>

          {/* User Info */}
          <div className="flex items-center space-x-4 border-t pt-4">
            {/* User Image */}
            <img
              src={item.imgSrc}
              alt={item.name}
              className="w-12 h-12 rounded-full object-cover"
            />

            {/* User Details */}
            <div>
              <h4 className="text-gray-900 font-semibold">{item.name}</h4>
              <p className="text-gray-500 text-xs">
                {item.designation}, {item.organization}
              </p>
            </div>
          </div>
        </div>
      ))
  ) : (
    <div className="col-span-full text-center py-6 text-gray-600">
      No testimonials available. Please add some.
    </div>
  )}
</div>




    </div>
  );
};

export default AddTestimonials;
