import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Button, Input, Textarea, Select, Option } from "@material-tailwind/react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { TailSpin } from 'react-loader-spinner';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import { ADD_CAREER_JOBS } from "../../Api";
import { EDIT_CAREER_JOBS } from "../../Api";
import { GET_CAREER_JOBS } from "../../Api";
import { JOB_STATUS } from "../../Api";



const AddCareers = () => {
    const [formData, setFormData] = useState({
        jobTitle: "",
        jobDescription: "",
        jobType: "",
        location: "kelambakkam, Chennai",
        salary: "",
        // maxSalary: "",
        numberOfOpenings: "",
        keyResponsibilities: [],
        educationalQualification: "",
        experience: "",
        skills: [],
        questions: [],
        preferences: [],
        emailAlerts: [],
    });

    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [responsibility, setResponsibility] = useState("");
    const [skill, setSkill] = useState("");
    const [question, setQuestion] = useState("");
    const [preference, setPreference] = useState("");
    const [emailAlert, setEmailAlert] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [useExistingSheet, setUseExistingSheet] = useState(false);
    const [createNewSheetForJob, setCreateNewSheetForJob] = useState(false);

    const [isJobEnabled, setIsJobEnabled] = useState(true);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const jobId = queryParams.get('jobid');

    const fetchJobs = useCallback(async () => {
        try {
            const response = await axios.get(GET_CAREER_JOBS, {
                params: { userSide: 'admin' },
                headers: { 'Content-Type': 'application/json' },
            });
    
            setJobs(response.data.jobs);
            const job = response.data.jobs.find(job => job.id === parseInt(jobId));
    
            if (job) {
                setFormData({
                    jobTitle: job.jobTitle,
                    jobDescription: job.jobDescription,
                    jobType: job.jobType,
                    location: job.location,
                    salary: job.salary,
                    numberOfOpenings: job.numberOfOpenings,
                    keyResponsibilities: job.keyResponsibilities,
                    educationalQualification: job.educationalQualification,
                    experience: job.experience,
                    skills: job.skills,
                    questions: job.questions,
                    preferences: job.preferences,
                    emailAlerts: job.emailAlerts,
                });
                setIsJobEnabled(job.jobStatus);
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
        } finally {
            setLoading(false);
        }
    }, [jobId]); 

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchJobs();
    }, [fetchJobs]); 

    const job = jobs.find(job => job.id === parseInt(jobId));

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleJobTypeChange = (value) => {
        setFormData((prev) => ({ ...prev, jobType: value }));
    };

    const handleAddResponsibility = () => {
        if (responsibility.trim()) {
            setFormData((prev) => ({
                ...prev,
                keyResponsibilities: [...prev.keyResponsibilities, responsibility],
            }));
            setResponsibility("");
        }
    };

    const handleAddSkill = () => {
        if (skill.trim()) {
            setFormData((prev) => ({
                ...prev,
                skills: [...prev.skills, skill],
            }));
            setSkill("");
        }
    };

    const handleAddQuestion = () => {
        if (question.trim()) {
            setFormData((prev) => ({
                ...prev,
                questions: [...prev.questions, question],
            }));
            setQuestion("");
        }
    };

    const handleAddPreference = () => {
        if (preference.trim()) {
            setFormData((prev) => ({
                ...prev,
                preferences: [...prev.preferences, preference],
            }));
            setPreference("");
        }
    };

    const handleAddEmail = () => {
        if (emailAlert.trim()) {
            setFormData((prev) => ({
                ...prev,
                emailAlerts: [...prev.emailAlerts, emailAlert],
            }));
            setEmailAlert("");
        }
    };

    const handleRemoveResponsibility = (index, e) => {
        e.preventDefault();
        setFormData((prev) => ({
            ...prev,
            keyResponsibilities: prev.keyResponsibilities.filter((_, i) => i !== index),
        }));
    };

    const handleRemoveSkill = (index, e) => {
        e.preventDefault();
        setFormData((prev) => ({
            ...prev,
            skills: prev.skills.filter((_, i) => i !== index),
        }));
    };

    const handleRemoveQuestion = (index, e) => {
        e.preventDefault();
        setFormData((prev) => ({
            ...prev,
            questions: prev.questions.filter((_, i) => i !== index),
        }));
    };

    const handleRemovePreference = (index, e) => {
        e.preventDefault();
        setFormData((prev) => ({
            ...prev,
            preferences: prev.preferences.filter((_, i) => i !== index),
        }));
    };

    const handleRemoveEmail = (index, e) => {
        e.preventDefault();
        setFormData((prev) => ({
            ...prev,
            emailAlerts: prev.emailAlerts.filter((_, i) => i !== index),
        }));
    };

    const handleCreateSubmit = async (e) => {
        e.preventDefault();

        console.log("Form Data:", formData);


        const toastId = toast.loading("Submitting job details...");

        try {
            const response = await axios.post(ADD_CAREER_JOBS, formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200 || response.status === 201) {

                toast.update(toastId, {
                    render: "Job details submitted successfully!",
                    type: "success",
                    isLoading: false,
                    autoClose: 3000,
                });

                setFormData({
                    jobTitle: "",
                    jobDescription: "",
                    jobType: "",
                    location: "kelambakkam, Chennai",
                    salary: "",
                    // maxSalary: "",
                    numberOfOpenings: "",
                    keyResponsibilities: [],
                    educationalQualification: "",
                    experience: "",
                    skills: [],
                    questions: [],
                    preferences: [],
                    emailAlerts: [],
                });
            } else {
                console.error("Error submitting job details:", response.data);

                toast.update(toastId, {
                    render: "Failed to submit job details. Please try again.",
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                });
            }
        } catch (error) {
            console.error("Error:", error);

            toast.update(toastId, {
                render: "An error occurred while submitting job details. Please try again.",
                type: "error",
                isLoading: false,
                autoClose: 3000,
            });
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();

        console.log("Form Data:");

        if (jobId) {
            const job = jobs.find((job) => job.id === parseInt(jobId));
            if (job) {
                const questionsMatch =
                    formData.questions.length === job.questions.length &&
                    formData.questions.every((question, index) => question === job.questions[index]);

                if (!questionsMatch) {
                    setIsModalOpen(true);
                    return;
                }
            }
        }

        await postApiData();

    };


    const handleModalSubmit = async () => {

        await postApiData();

        console.log("Use existing sheet:", useExistingSheet);
        console.log("Create new sheet:", createNewSheetForJob);

        setIsModalOpen(false);
    };


    const postApiData = async () => {
        const toastId = toast.loading("Submitting job details...");
        try {
            console.log("Form Data:", formData);
            // Initialize FormData object
            const formDataToSend = new FormData();

            // Append fields from the formData state
            Object.keys(formData).forEach((key) => {
                const value = formData[key];
                if (Array.isArray(value)) {
                    // If the value is an array, append it as a JSON string
                    formDataToSend.append(key, JSON.stringify(value));
                } else {
                    // Otherwise, append the key-value pair
                    formDataToSend.append(key, value);
                }
            });

            // Append additional fields
            formDataToSend.append('useExistingSheet', useExistingSheet ? 'true' : 'false');
            formDataToSend.append('createNewSheetForJob', createNewSheetForJob ? 'true' : 'false');
            formDataToSend.append('spreadsheetId', job.spreadsheetId || '');
            formDataToSend.append('resumeFolderId', job.resumeFolderId || '');
            formDataToSend.append('parentFolderId', job.parentFolderId || '');
            formDataToSend.append('id', job.id);
            formDataToSend.append('jobStatus', isJobEnabled || '');

            for (let pair of formDataToSend.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }

            // Send the form data to the backend using axios
            const response = await axios.post(EDIT_CAREER_JOBS, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
                },
            });

            // Log the API response
            console.log('API response:', response.data);

            fetchJobs();

            toast.update(toastId, {
                render: "Job details submitted successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
            });

            return response.data;
        } catch (error) {
            // Handle any errors
            console.error('Error while posting data:', error);

            toast.update(toastId, {
                render: "An error occurred while submitting job details. Please try again.",
                type: "error",
                isLoading: false,
                autoClose: 3000,
            });

            throw error;
        }
    };



    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <TailSpin
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                />
            </div>
        );
    }

    const handleToggle = async (jobTitle) => {
        const newJobEnabledState = !isJobEnabled;

        // If transitioning from false to true, open the modal and exit the function
        if (!isJobEnabled && newJobEnabledState) {
            setIsJobEnabled(newJobEnabledState);
            setIsModalOpen(true);
            return;
        }

        const toastId = toast.loading(`${isJobEnabled ? 'Disabling' : 'Enabling'} the job ${jobTitle}`);

        try {
            const response = await fetch(JOB_STATUS, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jobTitle: jobTitle,
                    isJobEnabled: newJobEnabledState,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update job status');
            }

            toast.update(toastId, {
                render: `Job ${newJobEnabledState ? 'enabled' : 'disabled'} successfully!`,
                type: 'success',
                isLoading: false,
                autoClose: 3000,
            });

            setIsJobEnabled(newJobEnabledState);
        } catch (error) {
            console.error(error);
            toast.update(toastId, {
                render: 'Error occurred while updating the job status!',
                type: 'error',
                isLoading: false,
                autoClose: 3000,
            });
        }
    };


    return (
        <div className="container mx-auto p-8 shadow-lg bg-white rounded-lg">
            {/* Horizontal Image */}
            {/* <div className="mb-8">
                <img
                    src="https://img.freepik.com/free-vector/low-poly-background-with-connecting-lines-dots_1048-20298.jpg?t=st=1735206547~exp=1735210147~hmac=de65301371de80f7e889fd6fcfd8e9d08f71339364760b7f390bbfb851e6d5cb&w=996"
                    alt="Job Posting Banner"
                    className="w-full h-[400px] object-cover rounded-lg"
                />
            </div> */}

            <Link
                to="/admincareer"
                className=" items-center space-x-2 bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300 shadow-md transform hover:scale-105 w-full sm:w-auto mb-4 sm:mb-0"
            >
                <FontAwesomeIcon icon={faArrowLeft} />
                <span>Go Back</span>
            </Link>

            <div className="flex items-center justify-between w-full mt-8 mb-8">

                <h1 className="text-4xl md:text-5xl font-extrabold">
                    {`${job ? 'Edit' : 'Create'} Job Posting`}
                </h1>

                {/* Toggle Section */}
                {job && (
                    /* Toggle Section */
                    <div className="flex items-center space-x-3">
                        <span className="text-gray-700 text-sm font-medium ml-2">
                            {isJobEnabled ? "Job Enabled" : "Job Disabled"}
                        </span>
                        <label htmlFor="theme-toggle" className="flex items-center cursor-pointer relative">
                            <input
                                type="checkbox"
                                id="theme-toggle"
                                className="sr-only"
                                checked={isJobEnabled}
                                onChange={() => handleToggle(job.jobTitle)}
                            />
                            <div className="relative flex items-center">
                                {/* Toggle Container */}
                                <div className="w-16 h-8 bg-gray-300 rounded-full shadow-inner transition-all duration-300 relative flex items-center justify-between px-2 transform hover:scale-105 hover:bg-gray-400">
                                    {/* Toggle Knob */}
                                    <div
                                        className={`dot absolute left-1 top-1 w-8 h-6 rounded-full shadow transform transition-all duration-300 ${isJobEnabled ? 'translate-x-6 bg-green-500' : 'translate-x-0 bg-red-500'}`}
                                    >
                                        {/* Eye Icon (Visible when Job is Disabled) */}
                                        {!isJobEnabled && (
                                            <FontAwesomeIcon
                                                icon={faEyeSlash}
                                                className="text-white absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2"
                                                size="sm"
                                            />
                                        )}
                                        {/* Eye Icon (Visible when Job is Enabled) */}
                                        {isJobEnabled && (
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                className="text-white absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2"
                                                size="sm"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                )}
            </div>

            <form onSubmit={job ? handleEditSubmit : handleCreateSubmit} className="space-y-6">
                {/* Job Title */}
                <div>
                    <Input
                        type="text"
                        name="jobTitle"
                        label="Job Title"
                        value={formData.jobTitle}
                        onChange={handleChange}
                        className="border p-3 w-full rounded-md"
                        required
                    />
                </div>

                {/* Job Description */}
                <div>
                    <Textarea
                        name="jobDescription"
                        label="Job Description"
                        value={formData.jobDescription}
                        onChange={handleChange}
                        className="border p-3 w-full rounded-md"
                        rows="4"
                        required
                    />
                </div>

                <div className="flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0">
                    {/* Job Type */}
                    <div className="flex-1">
                        <Select
                            name="jobType"
                            label="Job Type"
                            value={formData.jobType}
                            onChange={handleJobTypeChange}
                            className="border p-3 w-full rounded-md"
                            required
                        >
                            <Option value="">Select Job Type</Option>
                            <Option value="Full-Time">Full-Time</Option>
                            <Option value="Part-Time">Part-Time</Option>
                            <Option value="Contract">Contract</Option>
                            <Option value="Internship">Internship</Option>
                        </Select>
                    </div>

                    {/* Experience */}
                    <div className="flex-1">
                        <Input
                            type="text"
                            label="Experience"
                            name="experience"
                            value={formData.experience}
                            onChange={handleChange}
                            className="border p-3 w-full rounded-md"
                            min="0"
                            required
                        />
                    </div>

                    {/* Number of Openings */}
                    <div className="flex-1">
                        <Input
                            type="text"
                            label="Number of Openings"
                            name="numberOfOpenings"
                            value={formData.numberOfOpenings}
                            onChange={handleChange}
                            className="border p-3 w-full rounded-md"
                            min="0"
                            required
                        />
                    </div>
                </div>

                {/* Location, Min Salary, Max Salary */}
                <div className="flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0">
                    <div className="flex-1">
                        <Input
                            type="text"
                            name="location"
                            label="Location"
                            value={formData.location}
                            onChange={handleChange}
                            className="border p-3 w-full rounded-md"
                            required
                        />
                    </div>
                    <div className="flex-1">
                        <Input
                            type="text"
                            name="salary"
                            label="Salary"
                            value={formData.salary}
                            onChange={handleChange}
                            className="border p-3 w-full rounded-md"
                            min="0"
                        />
                    </div>

                    {/* Max Salary */}
                    {/* <div className="flex-1">
                        <Input
                            type="number"
                            name="maxSalary"
                            label="Max Salary"
                            value={formData.maxSalary}
                            onChange={handleChange}
                            className="border p-3 w-full rounded-md"
                            min="0"
                        />
                    </div> */}
                </div>

                {/* Educational Qualification */}
                <div>
                    <Textarea
                        type="text"
                        label="Educational Qualification"
                        name="educationalQualification"
                        value={formData.educationalQualification}
                        onChange={handleChange}
                        className="border p-3 w-full rounded-md"
                        rows="4"
                        required
                    />
                </div>

                <div className="flex flex-col md:flex-row md:space-x-4 w-full">
                    {/* Key Responsibilities */}
                    <div className="flex-1">
                        <div className="relative flex items-center">
                            <Input
                                type="text"
                                label="Key Responsibilities"
                                value={responsibility}
                                onChange={(e) => setResponsibility(e.target.value)}
                                className="border p-3 flex-grow rounded-md w-full"
                            />
                            <Button
                                type="button"
                                onClick={handleAddResponsibility}
                                className="absolute right-4 bg-green-500 text-white px-4 py-1 rounded-md"
                            >
                                Add
                            </Button>
                        </div>
                        <ul className="list-disc pl-5 mt-3 text-gray-600">
                            {formData.keyResponsibilities.map((item, index) => (
                                <li key={index} className="">
                                    <span>{item}</span>
                                    <button
                                        onClick={(e) => handleRemoveResponsibility(index, e)}
                                        className="text-red-500 hover:text-red-700 ml-2"
                                    >
                                        ✖
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Skills */}
                    <div className="flex-1">
                        <div className="relative flex items-center">
                            <Input
                                type="text"
                                label="Skills"
                                value={skill}
                                onChange={(e) => setSkill(e.target.value)}
                                className="border p-3 flex-grow rounded-md w-full"
                            />
                            <Button
                                type="button"
                                onClick={handleAddSkill}
                                className="absolute right-4 bg-green-500 text-white px-4 py-1 rounded-md"
                            >
                                Add
                            </Button>
                        </div>
                        <ul className="list-disc pl-5 mt-3 text-gray-600">
                            {formData.skills.map((item, index) => (
                                <li key={index} className="">
                                    <span>{item}</span>
                                    <button
                                        onClick={(e) => handleRemoveSkill(index, e)}
                                        className="text-red-500 hover:text-red-700 ml-2"
                                    >
                                        ✖
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row md:space-x-4 w-full">
                    {/* Key Responsibilities */}
                    <div className="flex-1">
                        <div className="relative flex items-center">
                            <Input
                                label="Question"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                className="border p-3 flex-grow rounded-md w-full"
                            />
                            <Button
                                type="button"
                                onClick={handleAddQuestion}
                                className="absolute right-4 bg-green-500 text-white px-4 py-1 rounded-md"
                            >
                                Add
                            </Button>
                        </div>
                        <ul className="list-disc pl-5 mt-3 text-gray-600">
                            {formData.questions.map((item, index) => (
                                <li key={index} className="">
                                    <span>{item}</span>
                                    <button
                                        onClick={(e) => handleRemoveQuestion(index, e)}
                                        className="text-red-500 hover:text-red-700 ml-2"
                                    >
                                        ✖
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Skills */}
                    <div className="flex-1">
                        <div className="relative flex items-center">
                            <Input
                                type="text"
                                label="Preference"
                                value={preference}
                                onChange={(e) => setPreference(e.target.value)}
                                className="border p-3 flex-grow rounded-md w-full"
                            />
                            <Button
                                type="button"
                                onClick={handleAddPreference}
                                className="absolute right-4 bg-green-500 text-white px-4 py-1 rounded-md"
                            >
                                Add
                            </Button>
                        </div>
                        <ul className="list-disc pl-5 mt-3 text-gray-600">
                            {formData.preferences.map((item, index) => (
                                <li key={index} className="">
                                    <span>{item}</span>
                                    <button
                                        onClick={(e) => handleRemovePreference(index, e)}
                                        className="text-red-500 hover:text-red-700 ml-2"
                                    >
                                        ✖
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row md:space-x-4 w-full">
                    <div className="flex-1">
                        <div className="relative flex items-center">
                            <Input
                                type="email"
                                label="Email Notifications"
                                value={emailAlert}
                                onChange={(e) => setEmailAlert(e.target.value)}
                                className="border p-3 flex-grow rounded-md w-full"
                            />
                            <Button
                                type="button"
                                onClick={handleAddEmail}
                                className="absolute right-4 bg-green-500 text-white px-4 py-1 rounded-md"
                            >
                                Add
                            </Button>
                        </div>
                        <ul className="list-disc pl-5 mt-3 text-gray-600">
                            {formData.emailAlerts.map((item, index) => (
                                <li key={index} className="">
                                    <span>{item}</span>
                                    <button
                                        onClick={(e) => handleRemoveEmail(index, e)}
                                        className="text-red-500 hover:text-red-700 ml-2"
                                    >
                                        ✖
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* Submit Button */}
                <div>
                    <Button
                        type="submit"
                        className="bg-blue-600 text-white px-4 py-3 rounded-md w-full font-medium"
                    >
                        Submit
                    </Button>
                </div>
            </form>


            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-xl w-110 max-w-xl">
                        {/* Modal Header */}
                        <div className="flex items-center justify-between mb-4">
                            <h2 className="text-xl font-bold text-red-500">Edit Confirmation</h2>
                        </div>

                        {/* Modal Content */}
                        <p className="text-gray-700 text-base mb-4">
                            You have made changes to the existing questions. This will affect the spreadsheet columns. Please choose one of the following options:
                        </p>

                        <div className="space-y-4">
                            {/* Option 1: Use existing sheet */}
                            <label className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={useExistingSheet}
                                    onChange={(e) => {
                                        setUseExistingSheet(e.target.checked);
                                        if (e.target.checked) {
                                            setCreateNewSheetForJob(false);
                                        }
                                    }}
                                    className="mr-2 w-5 h-5 text-green-500 border-gray-300 rounded focus:ring-green-400 focus:ring-opacity-50"
                                />
                                <span className="text-gray-800">Modify the existing spreadsheet by updating the columns.</span>
                            </label>

                            {/* Option 2: Create a new sheet for this job */}
                            <label className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={createNewSheetForJob}
                                    onChange={(e) => {
                                        setCreateNewSheetForJob(e.target.checked);
                                        if (e.target.checked) {
                                            setUseExistingSheet(false);
                                        }
                                    }}
                                    className="mr-2 w-5 h-5 text-green-500 border-gray-300 rounded focus:ring-green-400 focus:ring-opacity-50"
                                />
                                <span className="text-gray-800">Create a new, separate sheet for this job.</span>
                            </label>
                        </div>


                        {/* Footer Buttons */}
                        <div className="flex justify-end space-x-4 mt-6">
                            <button
                                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 text-gray-700"
                                onClick={() => setIsModalOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                                onClick={handleModalSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            )}


            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default AddCareers;